import request from '../../utils/request';
import { uploadFileToGcs } from '../upload/api';

export function detail(params: any) {
  const { id } = params;
  return request(`/drivers/${id}/application`, {
    method: 'GET'
  });
}

export function update(params: any) {
  return uploadDriverDocuments(params).then(driverParams =>
    request(`/drivers/${params.id}/application`, {
      method: 'PUT',
      data: driverParams
    })
  );
}

export function uploadDriverDocuments(params: any) {
  const { id, license, avatarUrl, bankVoidCheque, vehicleOwnership, vehicleInsurance, ...otherParams } = params;
  const uploadOneFile = (file: any, name: string) =>
    uploadFileToGcs({
      filePath: `${name === 'avatar' ? 'avatars' : 'documents/drivers'}/${id}/${name}.jpg`,
      file,
      fileType: 'image/jpg',
      privated: name === 'avatar' ? false : true
    });
  return Promise.all([
    uploadOneFile(license, 'license'),
    uploadOneFile(vehicleOwnership, 'vehicle-ownership'),
    uploadOneFile(vehicleInsurance, 'vehicle-insurance'),
    uploadOneFile(avatarUrl, 'avatar'),
    uploadOneFile(bankVoidCheque, 'bank-void-cheque'),
  ]).then(([p1, p2, p3, p4, p5]) => {
    return {
      ...otherParams,
      account: { ...otherParams.account, avatarUrl: p4.response },
      license: p1.response,
      vehicleOwnership: p2.response,
      vehicleInsurance: p3.response,
      bankVoidCheque: p5.response,
    };
  });
}