import * as api from './api'
import { State } from '../shipment-route/types'
import { moduleOutput, toCamelKey } from 'react-easy-module/es/module'

const DETAIL_DRIVER_ROUTE = 'DETAIL_DRIVER_ROUTE';
const TODAYUPDATE_SHIPMENT_STATUS = 'TODAYUPDATE_SHIPMENT_STATUS';
const START_DRIVER_ROUTE = 'START_DRIVER_ROUTE';

const initState: State = {
  request: {},
  items: {},
  metadata: {
    current: 1,
    pageSize: 10,
    total: 0,
    ids: []
  }
}

const inputs = [
  {
    action: DETAIL_DRIVER_ROUTE,
    apiPayload(payload: any) {
      return {
        api: api.detail,
        params: payload
      }
    },
    onFailure(state: any, action: any) {
      return {
        ...state,
        items: {}
      }
    },
    onSuccess(state: any, action: any) {
      const { params: { id } } = action;
      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...state.items[id],
            shipments: state.items[id].shipments.reduce((h: any, s: any) => Object.assign(h, {[s.id]: s}), {})
          }
        }
      }
    }
  },
  {
    action: TODAYUPDATE_SHIPMENT_STATUS,
    apiPayload(payload: any) {
      return {
        api: api.updateShipmentStatus,
        params: payload,
        next: payload.backToList ?  `/shipments?date=${payload.date}` : undefined
      }
    },
    onSuccess(state: any, action: any) {
      const { params: { id }, response: { data } } = action;
      return {
        ...state,
        items: {
          ...state.items,
          today: {
            ...state.items.today,
            shipments: {
              ...state.items.today.shipments,
              [id]: data
            }
          }
        }
      }
    }
  },
  {
    action: START_DRIVER_ROUTE,
    apiPayload(payload: any) {
      return {
        api: api.startRoute,
        params: payload
      }
    },
    onSuccess(state: any, action: any) {
      const {response: { data } } = action;
      return {
        ...state,
        items: {
          today: {
            ...data,
            shipments: data.shipments.reduce((h: any, s: any) => Object.assign(h, {[s.id]: s}), {})
          }
        }
      }
    }
  }
]

const {
  actions,
  reducer,
  sagas
} = moduleOutput(inputs)(initState);

export const doDetail = actions[toCamelKey(`DO_${DETAIL_DRIVER_ROUTE}`)];
export const doStartRoute = actions[toCamelKey(`DO_${START_DRIVER_ROUTE}`)];
export const doUpdateShipmentStatus = actions[toCamelKey(`DO_${TODAYUPDATE_SHIPMENT_STATUS}`)];
export const driverAppReducer = { driverApp: reducer };
export const driverAppSagas = sagas;