import { configStore } from 'react-easy-module/es/store';
import { authReducer, authSagas } from '../modules/auth';
import { driverAppReducer, driverAppSagas } from '../modules/driver-app';
import { driverApplicationReducer, driverApplicationSagas } from '../modules/driver-application';
import { errorReducer } from '../modules/error';
import configEnv from '../configs/env';

export default function() {
  return configStore({
    reducer: {
      ...authReducer,
      ...driverAppReducer,
      ...driverApplicationReducer,
      ...errorReducer
    },
    sagas: [
      ...authSagas,
      ...driverAppSagas,
      ...driverApplicationSagas
    ],
    debug: configEnv.NODE_ENV !== 'production'
  });
}
