const FAILURE_REGEX = /_failure$/i;
const DO_WIPE_ERROR = 'DO_WIPE_ERROR';

export type State = {
  status?: number
  message?: string
}

export const errorReducer = {
  error: (state: State = {}, action: any) => {
    switch (action.type) {
      case (action.type.match(FAILURE_REGEX) || {}).input:
        return {
          ...state,
          ...(action.payload?.error)
        };
      case DO_WIPE_ERROR:
        return {};
      default:
        return state;
    }
  }
}

export const doWipeError = () => ({ type: DO_WIPE_ERROR });
