import React from 'react';
import { connect } from 'react-redux';
import { doRegisterAccount, doResetPassword, doChangePassword } from '../index';
import { State } from '../types';

export type ActionProps = {
  request: any
  doRegisterAccount(_: any): void
  doResetPassword(_: any): void
  doChangePassword(_: any): void
}

export default <T extends ActionProps>(Component: React.ComponentType<T>): any => {
  class Container extends React.Component<ActionProps> {
    render() {
      return <Component {...this.props as T} />;
    }
  }
  function mapStateToProps(state: { auth: State }) {
    const { auth: { request } } = state;
    return {
      request: request.register || request.reset || request.change || {},
    }
  }
  return connect(
    mapStateToProps,
    {
      doRegisterAccount,
      doResetPassword,
      doChangePassword
    }
  )(Container);
}