import React from 'react';
import useItems, { ItemsProps, ITEM_TYPE } from './hooks/useItems';
import BaseForm, { BaseFormProps } from './Base';
import { useIntl } from "react-intl";

type Props = BaseFormProps

const PasswordForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  const items: ItemsProps[] = [
    {
      formItemProps: {
        name: 'password',
        label: intl.formatMessage({id: 'GLOBAL.PASSWORD'})
      },
      required: true,
      type: ITEM_TYPE.PASSWORD
    },
    {
      formItemProps: {
        name: 'confirmPassword',
        label: intl.formatMessage({id: 'GLOBAL.CONFIRM_PASSWORD'}),
        dependencies: ['password'],
        rules: [
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(`${intl.formatMessage({id: 'ERROR.PASSWORD_NOT_MATCHED'})}`);
            },
          })
        ]
      },
      required: true,
      type: ITEM_TYPE.PASSWORD
    }
  ];
  return (
    <BaseForm
      {...props}
      submitText={intl.formatMessage({id: 'ACTION.SUBMIT'})}
    >
      {useItems(items)}
    </BaseForm>
  )
}

export default PasswordForm;
