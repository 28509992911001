import React, { Component } from "react";
import { Select } from "antd";
import { loadGoogleMaps } from "../../utils/map";
import { SelectProps } from "antd/lib/select";

type Props = SelectProps<any> & {
  onGoogleMapLoaded?: () => void
  onChange?: (value?: any) => void
  onError?: (status: string) => void
}

type State = {
  googleMapsReady: boolean
  fetching: boolean
  suggestions: { value: string, label: string }[]
}

const debounce = (func: Function, waitFor: number = 0) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  return function(...args: any) {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => func(...args), waitFor);
  }
}

class AddressInput extends Component<Props, State> {
  state = {
    googleMapsReady: false,
    fetching: false,
    suggestions: []
  };
  autocompleteService: any;
  autocompleteOK: any;
  geocoderService: any;
  geocoderOK: any;
  unmounted: boolean = false;
  select: Select | null | undefined;

  componentDidMount() {
    loadGoogleMaps(() => {
      if (!(window as any).google) {
        throw new Error(
          "[react-places-autocomplete]: Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
        );
      }

      if (!(window as any).google.maps.places) {
        throw new Error(
          "[react-places-autocomplete]: Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
        );
      }
      this.autocompleteService = new (window as any).google.maps.places.AutocompleteService();
      this.autocompleteOK = (window as any).google.maps.places.PlacesServiceStatus.OK;
      this.geocoderService = new (window as any).google.maps.Geocoder();
      this.geocoderOK = (window as any).google.maps.GeocoderStatus.OK;
      if (!this.unmounted) {
        this.setState({ googleMapsReady: true }, this.props.onGoogleMapLoaded);
      }
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  onChange = (value?: any) => {
    if (!value) {
      this.props.onChange && this.props.onChange();
    }
    if (value && value.key) {
      this.geocoderService.geocode(
        { placeId: value.key },
        (results: any[], status: string) => {
          const result = results[0];
          const newValue: any = { ...value };
          let address1='';
          let city='';
          let province='';
          let postcode='';
          if (result) {
            for (const component of result.address_components) {
              const componentType = component.types[0];
                switch (componentType) {
                  case "street_number":
                    address1 = `${component.long_name} ${address1}`;
                    break;
                  case "route":
                    address1 += component.short_name;
                    break;
                  case "postal_code":
                    postcode = `${component.long_name}${postcode}`;
                    break;
                  case "postal_code_suffix":
                    postcode = `${postcode}-${component.long_name}`;
                    break;
                  case "locality":
                    city = component.long_name;
                    break;
                  case "administrative_area_level_1":
                    province = component.short_name;
                    break;
                }
              }
            newValue.label = `${address1}, ${city}, ${province}, ${postcode}`;
            newValue.value = `${result.geometry.location.lat()},${result.geometry.location.lng()}`;
          }
          if (!this.unmounted) this.props.onChange && this.props.onChange(newValue);
          if (status !== this.geocoderOK) this.props.onError && this.props.onError(status);
        }
      );
    }
  };

  render() {
    const { googleMapsReady, fetching, suggestions } = this.state;
    if (!googleMapsReady) return null;
    return (
      <Select
        listHeight={window.innerHeight}
        showSearch
        allowClear
        labelInValue
        loading={fetching}
        filterOption={false}
        onSearch={this.fetchAddress}
        style={{ width: "100%" }}
        {...this.props}
        onChange={this.onChange}
      >
        {suggestions.map((d: any) => (
          <Select.Option key={d.value} value={d.value}>
            {d.label}
          </Select.Option>
        ))}
      </Select>
    );
  }

  fetchAddress = debounce((value: any) => {
    if (!this.unmounted) this.setState({ fetching: true });
    this.autocompleteService.getPlacePredictions(
      { input: value || "test" },
      (predictions: any[], status: string) => {
        if (predictions && predictions.length !== 0) {
          const newState: State = { ...this.state, fetching: false };
          if (status !== this.autocompleteOK) this.props.onError && this.props.onError(status);
          newState.suggestions = predictions.map(p => ({
            value: p.place_id,
            label: p.description
          }));
          if (!this.unmounted) this.setState(newState);
        }
      }
    );
  }, 800);
}

export default AddressInput;
