import request from '../../utils/request';

export function signIn(params: any) {
  return request("/auth/sign-in", {
    method: "POST",
    data: params
  });
}

export function refreshSession(params: any) {
  return request("/auth/refresh", {
    method: "GET",
    params
  });
}

export function signUp(params: any) {
  return request("/auth/sign-up", {
    method: "POST",
    data: params
  });
}

export function resetPassword(params: any) {
  return request("/auth/reset-password", {
    method: "GET",
    params
  });
}

export function updatePassword(params: any) {
  return request("/auth/reset-password", {
    method: "PUT",
    data: params
  });
}