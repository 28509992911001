import React from 'react';
import { UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import { useIntl } from "react-intl";
import './Foot.scss';

export enum NAV_TAB {
  SHIPMENTS,
  PROFILE
}

type Props = {
  onTabClick?(tab: NAV_TAB): void
}

const DriverAppFoot: React.FC<Props> = ({
  onTabClick
}) => {
  const intl = useIntl();
  return (
    <div className="DriverAppFoot">
      <div className="tab" onClick={() => onTabClick && onTabClick(NAV_TAB.SHIPMENTS)}>
        <UnorderedListOutlined />
        <div className="name">{intl.formatMessage({id: 'HEADER.SHIPMENTS'})}</div>
      </div>
      <div className="tab" onClick={() => onTabClick && onTabClick(NAV_TAB.PROFILE)}>
        <UserOutlined />
        <div className="name">{intl.formatMessage({id: 'HEADER.PROFILE'})}</div>
      </div>
    </div>
  );
}

export default DriverAppFoot;
