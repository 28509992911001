
import { IModuleState } from "react-easy-module/es/module";
import { Account } from '../account/types';

export enum DRIVER_STATUS {
  SIGNED_UP = 'SIGNED_UP',
  PREPARING_DOCUMENT = 'PREPARING_DOCUMENT',
  SUBMITTED_DOCUMENT = 'SUBMITTED_DOCUMENT',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED'
}

export type Driver = {
  id: string;
  startAddress: string;
  startLatitude: number;
  startLongitude: number;
  homeAddress: string;
  homeLatitude: number;
  homeLongitude: number;
  account?: Account;
  status?: DRIVER_STATUS;
  license?: string
  vehicleOwnership?: string
  vehicleInsurance?: string
  licensePlateNumber?: string
  vehicleMake?: string
  vehicleModel?: string
  vehicleType?: string
  vehicleColour?: string
  bankInstitution?: string
  branchNumber?: string
  institutionNumber?: string
  accountNumber?: string
  wMonday?: boolean
  wTuesday?: boolean
  wWednesday?: boolean
  wThursday?: boolean
  wFriday?: boolean
  wSaturday?: boolean
  wSunday?: boolean
  availabilityFrom?: string
  availabilityTo?: string
  drivingSpeed?: number
  spoken?: boolean
  contractAccepted?: boolean
  phone?: string
  referral?: string
  numOfRoutes?: number
  driverExtraHourlyPay: number
  driverHourlyPay?: number
  serviceArea?: {
    id: number
    name: string
    driverBaseHourlyPay: number
  }
}

export type Metadata = {
  current: number;
  pageSize: number;
  total: number;
  ids: string[]
}

export type State = IModuleState & {
  items: { [key: string]: Driver }
  metadata: Metadata
}
