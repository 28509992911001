import React, { FC, Fragment, useEffect, useContext } from 'react';
import {
  LoginPage,
  LogoutPage,
  DriverSignUpPage,
  DriverApplicationPage,
  ResetPasswordPage,
  E403Page,
  E404Page
} from './pages';
import {
  DriverAppShipmentsPage,
  DriverAppShipmentPage,
  DriverAppProfilePage
} from './pages/driver-app';
import AppError from '../components/AppError';
import {  Route, Switch } from 'react-router-dom';
import PRoute from '../modules/auth/containers/PRoute';
import withAccount, { AccountProps } from '../modules/auth/hocs/withAccount';
import withRoles from '../modules/auth/hocs/withRoles';
import { ROLES } from '../modules/role/types';
import { ConfigProvider } from 'antd';
import { LocaleContext } from './contexts/locale';


const withDriver = withRoles([ROLES.DRIVER], E403Page);

const App: FC<AccountProps> = ({account, doRefreshSession}) => {
  const localeContext = useContext(LocaleContext);
  const handleLanguageChange = (context: any, value: string) => {
    context.changeLanguage(value);
  }
  
  const handleSetAntdLocale = (context: any) => {
    return context.getAntdLocale();
  }

  useEffect(() => {
    account && doRefreshSession({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    account && handleLanguageChange(localeContext, account.language || 'en');
    // eslint-disable-next-line
  }, [account]);

  return (
    <ConfigProvider locale={handleSetAntdLocale(localeContext)}>
      <Fragment>
        <AppError />
        <Switch>
          <PRoute exact path="/application" component={withDriver(DriverApplicationPage)} />
          <PRoute exact path="/(shipments)?" component={withDriver(DriverAppShipmentsPage)} />
          <PRoute exact path="/shipments/:id" component={withDriver(DriverAppShipmentPage)} />
          <PRoute exact path="/profile" component={withDriver(DriverAppProfilePage)} />
          {/* PUBLIC  */}
          <Route exact path="/reset-password" component={ResetPasswordPage} />
          <Route exact path="/sign-up" component={DriverSignUpPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/logout" component={LogoutPage} />
          <Route path="*" component={E404Page} />
        </Switch>
      </Fragment>
    </ConfigProvider>
  )
}

export default withAccount(App);
