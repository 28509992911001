import { createSelector } from 'reselect';
import { State, Driver } from './types';

export const applicationSelector = createSelector(
  (state: State, id: string) => state.items,
  (state: State, id: string) => id,
  (items, id): Driver | undefined => {
    const driver: Driver = items[id];
    if (!driver) return undefined;
    return { ...driver};
  }
);