import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { UploadOutlined } from '@ant-design/icons';
import './PhotoUpload.scss';

const { Dragger } = Upload;

async function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
}

type Props = UploadProps & {
  icon?: string
}

const PhotoUploadInput: React.FC<Props> = props => {
  const photo = (props.id && (props as any)[props.id]) || undefined;
  const [preview, setPreview] = useState<string|undefined>();
  const handlePhotoChange = async () => {
    if (typeof photo === 'string') {
      setPreview(photo);
    } else if (photo?.file) {
      photo?.file && setPreview(await getBase64(photo.file))
    } else {
      setPreview(undefined);
    }
  }
  useEffect(() => {
    photo && handlePhotoChange();
    // eslint-disable-next-line
  },[photo]);
  const imageSrc = preview && preview.indexOf('http') > -1 ? preview+`?t=${new Date().valueOf()}` : preview;
  return (
    <Dragger
      className="PhotoUploadInput"
      showUploadList={false}
      beforeUpload={() => false}
      multiple={false}
      accept="image/*"
      {...props}
    >
      {!preview &&
        <div className="icon-wrapper">
          {props.icon
            ? <img src={props.icon} className="icon" alt="a"/>
            : <UploadOutlined />
          }
        </div>
      }
      {preview &&
        <div className="photo-wrapper">
          <img src={imageSrc} className="photo" alt="b"/>
        </div>
      }
    </Dragger>
  )
}

export default PhotoUploadInput;