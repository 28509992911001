import React from "react";
import { connect } from "react-redux";
import { doDetail, doUpdateShipmentStatus } from "../index";
import { State } from "../../shipment-route/types";
import { Shipment } from "../../shipment/types";
import { routeShipmentSelector } from '../selectors';

export type Props = {
  id: string
  request: any
  shipment?: Shipment
  doDetail(_: any): void
  doUpdateShipmentStatus(_: any): void
}

type OwnProps = {
  id: string;
}

export default <T extends Props>(Component: React.ComponentType<T>): any => {
  class Container extends React.Component<Props> {
    render() {
      return <Component {...this.props as T} />;
    }
  }
  function mapStateToProps({ driverApp }: { driverApp: State }, props: OwnProps) {
    return {
      request: driverApp.request.todayupdate || {},
      shipment: routeShipmentSelector(driverApp, 'today', props.id)
    }
  }
  return connect(
    mapStateToProps,
    {
      doDetail,
      doUpdateShipmentStatus
    }
  )(Container);
}