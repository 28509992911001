import request from '../../utils/request';
import { uploadFileToGcs } from '../upload/api';

export async function update(params: any) {
  const { id, photos, stopNumber, ...data } = params;
  const uploadOnePhoto = (file: any, name: string) =>
    uploadFileToGcs({
      filePath: `shipments/${id}/${name}.jpg`,
      file,
      fileType: 'image/jpg'
    });
  const results = await Promise.all<{response?: any, error?: any}[]>(
    photos.map((photo: any, index: number) => uploadOnePhoto(photo, `${new Date().valueOf()}-${index+1}`))
  );
  const failured: any = results.filter((u: any) => u.error)[0];
  if (failured) { 
    return {
      error: {
        ...failured.error,
        message: `Stop ${stopNumber} image saving has failed: ${failured.error?.message}`,
      },
    };
  }
  const response = await request(`/shipments/${id}/status`, {
    method: 'PUT',
    data: {
      ...data,
      photos: results.map((b: any) => (b.response?.split('?')||[])[0])
    },
       });
  if (response.error) {
    return {
      error: {
        ...response.error,
        message: `Stop ${stopNumber} saving has failed: ${response.error?.message}`,
      },
    }
  }
  return response;
}