import { createSelector } from 'reselect';
import { SHIPMENT_STATUS, Shipment } from '../shipment/types';
import { DriverRoute, State } from './types';

export const routeSelector = createSelector(
  (state: State, id: string) => state.items,
  (state: State, id: string) => id,
  (items, id): DriverRoute | undefined => {
    const route: DriverRoute = items[id];
    if (!route) return undefined;
    const shipments = Object.values(route.shipments||{}).sort((s1, s2) => ((s1.stopNumber||0) - (s2.stopNumber||0))) || [];
    return {
      ...route,
      shipmentIds: shipments.map(s => s.id)
    };
  }
);

export const routeShipmentSelector = createSelector(
  (state: State, routeId: string, id: string) => state.items,
  (state: State, routeId: string, id: string) => routeId,
  (state: State, routeId: string, id: string) => id,
  (items, routeId, id): Shipment | undefined => {
    const route: DriverRoute = items[routeId];
    if (!route || !route.shipments) return undefined;
    const shipment = route.shipments[id];
    if (!shipment) return;
    const mpsStopMap: { [key: string]: boolean } = {};
    const mpsTrackingId = shipment.order?.mpsTrackingId;
    const mpsStops: Shipment[] = [];
    const shipments = Object.values(route.shipments||{}).sort((s1, s2) => ((s1.stopNumber||0) - (s2.stopNumber||0))) || [];
    for (const stop of shipments) {
      const stopMpsTrackingId = stop.order?.mpsTrackingId;
      if (mpsTrackingId && mpsTrackingId === stopMpsTrackingId) {
        mpsStops.push(stop);
      }
      if (stopMpsTrackingId) {
        mpsStopMap[stopMpsTrackingId] = true;
      }
    }
    return {
      ...shipment,
      mpsStops
    };
  }
);