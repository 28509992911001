import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import withAccount, { AccountProps } from "../../modules/auth/hocs/withAccount"

const LogoutPage: React.FC<AccountProps> = ({
  account,
  doLogoutSession
}) => {
  useEffect(() => {
    doLogoutSession({});
    // eslint-disable-next-line
  }, []);
  if (!account) {
    return <Redirect to="/login" />
  }
  
  return null;
};

export default withAccount(LogoutPage);