import React from "react";
import { connect } from "react-redux";
import { doRefreshSession, doLogoutSession } from "../index";
import { State } from "../types";
import { Account } from "../../account/types";

export type AccountProps = {
  account?: Account;
  doRefreshSession(_: any): void
  doLogoutSession(_: any): void
}

export default <T extends AccountProps>(Component: React.ComponentType<T>): any => {
  class Container extends React.Component<AccountProps> {
    render() {
      return <Component {...this.props as T} />;
    }
  }
  function mapStateToProps(state: { auth: State }) {
    const { auth: { account } } = state;
    return {
      account
    }
  }
  return connect(mapStateToProps, {
    doRefreshSession,
    doLogoutSession
  })(Container);
}
