export { default as LOCALES } from './locale';
export const DEFAULT_LOCALE = 'en';
export const AUTH_KEY = 'edw';
// (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
// (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
// (?=.*[0-9])	The string must contain at least 1 numeric character
// (?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
// (?=.{10,})	The string must be eight characters or longer
export const STRONG_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/;
export const FRONTEND_DATE_FORMAT = 'YYYY-MM-DD';
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const BACKEND_TIME_FORMAT = 'HH:mm';
export const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export enum WEEKDAY {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}
export const COMPLETE_TYPE: any = {
  1: 'Handed to customer',
  2: 'Front door',
  3: 'Concierge / Front Desk / Security'
}
export const SKIP_REASON: any = {
  1: 'No answer to phone or buzzer',
  2: 'Need additional information',
  3: 'Package not received from warehouse',
  5: 'Refused by security or concierge',
  6: 'Business closed'
};

export const REASON_IDS_REQUIRED_POD = [1, 4, 5];
