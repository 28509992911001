import React from 'react';
import useItems, { ItemsProps, ITEM_TYPE } from './hooks/useItems';
import BaseForm, { BaseFormProps } from './Base';

type Props = BaseFormProps & {
  email?: string;
  referrerCode?: string;
}

const DriverSignUpForm: React.FC<Props> = (props) => {
  const items: ItemsProps[] = [
    {formItemProps: {name: 'firstName', label: 'First Name'}, required: true},
    {formItemProps: {name: 'lastName', label: 'Last Name'}, required: true},
    {formItemProps: {name: 'email', label: 'Email', initialValue: props.email}, required: true, type: ITEM_TYPE.EMAIL},
    {formItemProps: {name: 'password', label: 'Password'}, required: true, type: ITEM_TYPE.PASSWORD},
    {
      formItemProps: {
        name: 'confirmPassword',
        label: 'Confirm Password',
        dependencies: ['password'],
        rules: [
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords that you entered do not match!');
            },
          })
        ]
      },
      required: true,
      type: ITEM_TYPE.PASSWORD
    },
    {formItemProps: {name: 'phone', label: 'Phone Number'}, required: true},
    {formItemProps: {name: 'referrerCode', label: 'Referral Code (8 Alphanumeric Characters) (Optional)', initialValue: props.referrerCode}, inputProps: { disabled: !!props.referrerCode }},
  ];
  return (
    <BaseForm
      {...props}
      submitText="Sign Up"
    >
      {useItems(items)}
    </BaseForm>
  )
}

export default DriverSignUpForm;