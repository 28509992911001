import React, { Component } from "react";
import { connect } from "react-redux";
import { State } from "../types";
import { ROLES } from "../../role/types";

interface IProps {
  auth: State
}

export default (allowedRoles: ROLES[], ForbiddenPage: React.ComponentType) => (Page: React.ComponentType<any>) => {
  class Container extends Component<IProps> {
    render() {
      const {
        auth: { account },
        ...props
      } = this.props;
      const roles = allowedRoles.filter(r => account?.roles.includes(r));
      if (roles.length === 0) {
        return <ForbiddenPage />;
      }
      return <Page {...props} />;
    }
  }
  return connect((state: { auth: State }) => ({
    auth: state.auth
  }))(Container);
};
