import React from 'react';
import useItems, { ITEM_TYPE, ItemsProps } from './hooks/useItems';
import BaseForm, { BaseFormProps } from './Base';
import { Alert, Button, Form } from 'antd';
import { WEEKDAYS } from '../../constants';
import { Driver } from '../../modules/driver-application/types';
import './DriverApplication.scss';
import { DriverContractView } from '../views';
import { useIntl } from "react-intl";

export enum STEP_KEY {
  DRIVERS_LICENSE = 'drivers-license',
  VEHICLE_OWNERSHIP = 'vehicle-ownership',
  VEHICLE_INSURANCE = 'vehicle-insurance',
  VEHICLE_DETAILS = 'vehicle-details',
  WEEKLY_AVAILABILITY = 'weekly-availability',
  HOME_ADDRESS = 'home-address',
  PAYMENT = 'payment',
  CONTRACT = 'contract',
}

export type Step = {
  key: STEP_KEY
  name: string
  desc?: string
}

type Props = BaseFormProps & {
  driver: Driver
  step: Step
  nextStep?: Step
  disabled?: boolean
}

const DriverApplicationForm: React.FC<Props> = ({
  driver,
  step,
  nextStep,
  onSubmit,
  loading,
  disabled,
  ...props
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const items: ItemsProps[] = [];
  const uploadFields = [STEP_KEY.DRIVERS_LICENSE, STEP_KEY.VEHICLE_OWNERSHIP, STEP_KEY.VEHICLE_INSURANCE]
  const hasUploadField = uploadFields.includes(step.key);
  for (const stepKey of uploadFields) {
    if (step.key === stepKey) {
      items.push(
        {
          inputProps: { icon: `/icons/${stepKey}.svg`, disabled },
          formItemProps: {name: stepKey, valuePropName: stepKey},
          required: true,
          type: ITEM_TYPE.UPLOAD_PHOTO
        }
      )
    }
  }
  if (step.key === STEP_KEY.VEHICLE_DETAILS) {
    items.push(
      {formItemProps: {name: 'licensePlateNumber', label: intl.formatMessage({id: 'DRIVER_APPLICATION.VEHICLE.LICENSE_PLATE_NUMBER'})}, required: true, inputProps: { size: "large", disabled }},
      {formItemProps: {name: 'vehicleMake', label: intl.formatMessage({id: 'DRIVER_APPLICATION.VEHICLE.MAKE'})}, required: true, inputProps: { size: "large", disabled }},
      {formItemProps: {name: 'vehicleModel', label: intl.formatMessage({id: 'DRIVER_APPLICATION.VEHICLE.MODEL'})}, required: true, inputProps: { size: "large", disabled }},
      {formItemProps: {name: 'vehicleType', label: intl.formatMessage({id: 'DRIVER_APPLICATION.VEHICLE.TYPE'})}, required: true, inputProps: { size: "large", disabled }},
      {formItemProps: {name: 'vehicleColour', label: intl.formatMessage({id: 'DRIVER_APPLICATION.VEHICLE.COLOUR'})}, required: true, inputProps: { size: "large", disabled }}
    )
  }
  if (step.key === STEP_KEY.WEEKLY_AVAILABILITY) {
    items.push(
      {
        formItemProps: {name: 'weeklyAvailability'},
        inputProps: { options: WEEKDAYS.map(w => ({label: intl.formatMessage({id: `GLOBAL.WEEKDAY.${w.toUpperCase()}`}), value: w})), size: "large", disabled },
        type: ITEM_TYPE.CHECKBOX_GROUP,
        required: true
      },
    )
  }
  if (step.key === STEP_KEY.HOME_ADDRESS) {
    items.push(
      {
        formItemProps: {name: 'address', label: intl.formatMessage({id: 'DRIVER_APPLICATION.HOME_ADDRESS'})},
        inputProps: { size: "large", disabled },
        required: true,
        type: ITEM_TYPE.ADDRESS
      }
    )
  }
  if (step.key === STEP_KEY.CONTRACT) {
    items.push(
      {
        formItemProps: {name: 'contractAccepted', valuePropName: 'checked'},
        inputProps: {label: intl.formatMessage({id: 'ACTION.I_ACCEPT'})},
        required: true,
        type: ITEM_TYPE.CHECKBOX,
        col: 12
      }
    )
  }
  if (step.key === STEP_KEY.PAYMENT) {
    items.push(
      {formItemProps: {name: 'bankInstitution', label: intl.formatMessage({id: 'DRIVER_APPLICATION.BANK_INFO.INSTITUTION'})}, inputProps: { size: "large", disabled }},
      {
        formItemProps:
        {
          name: 'branchNumber',
          label: intl.formatMessage({ id: 'DRIVER_APPLICATION.BANK_INFO.BRANCH_NUMBER' }),
          rules: [
            () => ({
              validator(_, value) {
                if (!value) return Promise.reject(intl.formatMessage({id: 'VALIDATION.REQUIRED'}));

                if (!value.toString().match(/^[0-9]{5,5}$/g)) {
                  return Promise.reject(intl.formatMessage({ id: 'VALIDATION.TRANSIT_NUMBER_RESTRICTION' }));
                }

                return Promise.resolve();
              }
            })
          ]
        },
        inputProps: { size: "large", disabled },
      },
      {
        formItemProps: 
        {
          name: 'institutionNumber',
          label: intl.formatMessage({id: 'DRIVER_APPLICATION.BANK_INFO.INSTITUTION_NUMBER'}),
          rules: [
            () => ({
              validator(_, value) {
                if (!value) return Promise.reject(intl.formatMessage({id: 'VALIDATION.REQUIRED'}));

                if (!value.toString().match(/^[0-9]{3,3}$/g)) {
                  return Promise.reject(intl.formatMessage({ id: 'VALIDATION.INSTITUTION_NUMBER_RESTRICTION' }));
                }

                return Promise.resolve();
              }
            })
          ]
        },
        inputProps: { size: "large", disabled },
      },
      {
        formItemProps:
        {
          name: 'accountNumber',
          label: intl.formatMessage({id: 'DRIVER_APPLICATION.BANK_INFO.ACCOUNT_NUMBER'}),
          rules: [
            () => ({
              validator(_, value) {
                if (!value) return Promise.reject(intl.formatMessage({id: 'VALIDATION.REQUIRED'}));

                if (!value.toString().match(/^[0-9]{7,15}$/g)) {
                  return Promise.reject(intl.formatMessage({ id: 'VALIDATION.ACCOUNT_NUMBER_RESTRICTION' }));
                }

                return Promise.resolve();
              }
            })
          ]
        },
        inputProps: { size: "large", disabled },
      },
    )
  }
  const handleTakePhotoChange = (e: any) => {
    form.setFieldsValue({[step.key]: {file: e.target.files[0]}})
  }
  const handleSubmit = (values: any) => {
    const params: any = {...values};
    for (const fieldName of Object.keys(values)) {
      const fieldValue = values[fieldName];
      if (!fieldValue) continue;
      switch (fieldName) {
        case 'drivers-license':
          delete params[fieldName];
          params.license = fieldValue.file || fieldValue;
          break;
        case 'vehicle-ownership':
          delete params[fieldName];
          params.vehicleOwnership = fieldValue.file || fieldValue;
          break;
        case 'vehicle-insurance':
          delete params[fieldName];
          params.vehicleInsurance = fieldValue.file || fieldValue;
          break;
        case 'weeklyAvailability':
          delete params[fieldName];
          WEEKDAYS.forEach(w => {
            Object.assign(params, {[`w${w}`]: fieldValue.includes(w)})
          })
          break;
        case 'address':
          delete params[fieldName];
          const latLng = fieldValue.value.split(',');
          params.homeAddress = fieldValue.label;
          params.homeLatitude = latLng[0];
          params.homeLongitude = latLng[1];
          break;
      }
    }
    onSubmit && onSubmit(params);
  }
  const getInitialValues = () => {
    return {
      ...driver,
      "drivers-license": driver.license,
      "vehicle-ownership": driver.vehicleOwnership,
      "vehicle-insurance": driver.vehicleInsurance,
      "weeklyAvailability": WEEKDAYS.filter(w => (driver as any)[`w${w}`]),
      address: ({ label: driver.homeAddress, value: `${driver.homeLatitude},${driver.homeLongitude}`}) || undefined,
    }
  }
  return (
    <BaseForm
      {...props}
      submitBtnVisible={false}
      onSubmit={handleSubmit}
      formProps={{
        form,
        className: 'DriverApplicationForm',
        initialValues: getInitialValues()
      }}
    >
      {hasUploadField && !disabled &&
        <Alert
          message={intl.formatMessage({id: 'ALERT.OPEN_APP_ON_PHONE'})}
          type="info"
          showIcon
          closable
        />
      }
      {step.key === STEP_KEY.CONTRACT &&
        <div className="driver-contract-wrapper">
          <DriverContractView />
        </div>
      }
      {useItems(items)}
      {hasUploadField && !disabled &&
        <label className="take-photo-btn">
          {intl.formatMessage({id: 'ALERT.TAKE_PHOTO'})}
          <input type="file" accept="image/*;capture=camera" onChange={handleTakePhotoChange} />
        </label>
      }
      {!disabled &&
        <Button type="primary" htmlType="submit" loading={loading} size="large" shape="round" block>
          {nextStep ? `${intl.formatMessage({id: 'ACTION.NEXT'})}: ${nextStep.name}` : intl.formatMessage({id: 'ACTION.OVERVIEW'})}
        </Button>
      }
    </BaseForm>
  )
}

export default DriverApplicationForm;
