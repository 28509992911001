import { Button } from 'antd';
import React from 'react';
import withProfile, { Props as ProfileProps } from '../../../modules/driver-app/hocs/withProfile';
import { AppFoot, AppHead } from '../../views/driver-app';
import { NAV_TAB } from '../../views/driver-app/Foot';
import withPage, { PageProps } from '../withPage';
import { useIntl } from "react-intl";
import { LanguageSelectInput } from '../../inputs';

type Props = ProfileProps & PageProps;

const DriverAppProfilePage: React.FC<Props> = ({
  account,
  pushTo
}) => {
  const intl = useIntl();
  const handleTabClick = (tab: NAV_TAB) => {
    if (tab === NAV_TAB.SHIPMENTS) {
      pushTo({path: "/shipments"});
    }
    if (tab === NAV_TAB.PROFILE) {
      pushTo({path: "/profile"});
    }
  }
  return (
    <div className="DriverAppProfilePage" style={{marginBottom: 50, marginTop: 56, padding: 20}}>
      <AppHead
        title={intl.formatMessage({id: 'GLOBAL.PROFILE'})}
      />
      <table className="descriptions">
        <tbody>
          <tr><td>{intl.formatMessage({id: 'GLOBAL.NAME'})}</td><td>{account?.name}</td></tr>
          <tr><td>{intl.formatMessage({id: 'GLOBAL.EMAIL'})}</td><td>{account?.email}</td></tr>
          <tr><td>{intl.formatMessage({id: 'GLOBAL.LANGUAGE'})}</td><td> <LanguageSelectInput style={{position: 'static'}}/></td></tr>
        </tbody>
      </table>
      <br/>
      <Button
        onClick={() => pushTo({path: '/logout'})}
        type="primary"
        block
      >
        {intl.formatMessage({id: 'ACTION.LOGOUT'})}
      </Button>
      <AppFoot
        onTabClick={handleTabClick}
      />
    </div>
  )
}

export default withPage(withProfile(DriverAppProfilePage));
