import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { doLoginSession } from '../index';
import { State } from '../types';

export type AuthProps = {
  isFetching: boolean
  authenticated: boolean
  doLoginSession(_: any): void
}

export default <T extends AuthProps>(Component: React.ComponentType<T>): any => {
  class Container extends React.Component<AuthProps> {
    render() {
      const { authenticated } = this.props;
      return authenticated ? <Redirect to="/"/> : <Component {...this.props as T} />;
    }
  }
  function mapStateToProps(state: { auth: State }) {
    const { auth: { request, authenticated } } = state;
    return {
      isFetching: request.login?.isFetching,
      authenticated
    }
  }
  return connect(
    mapStateToProps,
    {
      doLoginSession
    }
  )(Container);
}
