import { Button, Input } from 'antd';
import React, { useState } from 'react';
import { useIntl } from "react-intl";
import './Editable.scss';

type Props = {
  value?: string
  visible?: boolean
  onChange?(value?: string): void
  onCancel?(): void
  onSubmit?(value?: string): void
}

type State = {
  visible?: boolean
  value?: string
}

const EditableInput: React.FC<Props> = ({
  value,
  visible,
  onSubmit,
  onCancel
}) => {
  const intl = useIntl();
  const [state, setState] = useState<State>({
    value,
    visible
  });
  const handleChange = (e: any) => {
    setState({...state, value: e.target.value});
  }
  const handleSubmit = () => {
    onSubmit && onSubmit(state.value);
  }
  return (
    <div className="EditableInput">
      {visible &&
        <>
          <Input value={state.value} onChange={handleChange}/>
          <div className="action">
            <Button onClick={onCancel}>{intl.formatMessage({id: 'ACTION.CANCEL'})}</Button>
            <Button type="primary" disabled={!state.value} onClick={handleSubmit}>{intl.formatMessage({id: 'ACTION.SAVE'})}</Button>
          </div>
        </>

      }
      {!visible &&
        <label>{value}</label>
      }
    </div>
  )
}

export default EditableInput;
