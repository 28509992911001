import configEnv from '../configs/env';

export function decodePolyline(encoded: string) {
  // array that holds the points
  var points = []
  var index = 0,
    len = encoded.length;
  var lat = 0,
    lng = 0;
  while (index < len) {
    var b, shift = 0,
      result = 0;
    do {

      b = encoded.charAt(index++).charCodeAt(0) - 63; //finds ascii                                                                                    //and substract it by 63
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    var dlat = ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
    lat += dlat;
    shift = 0;
    result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    var dlng = ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
    lng += dlng;

    points.push([(lng / Math.pow(10, 6)), (lat / Math.pow(10, 6))]);

  }
  return points
}

export const loadGoogleMaps = function(callback: () => void) {
  const existingScript = document.getElementById("googleMaps");

  if (!existingScript) {
    const script: any = document.createElement("script");
    script.src = configEnv.REACT_APP_GOOGLE_MAP_URL;
    script.id = "googleMaps";
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) {
    setTimeout(() => {
      callback();
    }, 1000);
  }
};
