import React from 'react';
import withShipment, { Props as ShipmentProps } from '../../../modules/driver-app/hocs/withShipment';
import { SHIPMENT_STATUS } from '../../../modules/shipment/types';
import { useIntl } from "react-intl";
import './Shipment.scss';

type Props = ShipmentProps & {
  showMore?: boolean
  onClick?(): void
}

const DriverAppShipment: React.FC<Props> = ({
  shipment,
  showMore,
  onClick
}) => {
  const intl = useIntl();
  if (!shipment) return null;
  const mpsStops = shipment.mpsStops && shipment.mpsStops.length > 1 ? shipment.mpsStops : [shipment];
  const isMpsStop = mpsStops.length > 1;
  const isFirstMps = isMpsStop && mpsStops[0].id === shipment.id;
  if (isMpsStop && !isFirstMps) {
    return null;
  }
  const firstPhoto = shipment.photos && shipment.photos[0];
  const isSkipped = shipment.status === SHIPMENT_STATUS.SKIPPED;
  return (
    <div className="DriverAppShipment" onClick={onClick}>
      <div className="picture">
        {firstPhoto &&
          <img alt="shipment" src={firstPhoto} />
        }
        {!firstPhoto && isSkipped &&
          <div className={SHIPMENT_STATUS.SKIPPED}>{shipment.status}</div>
        }
      </div>
      <div className="stop-number-wrapper">
        {mpsStops.map(stop =>
          <div key={stop.id} className={`stop-number ${stop.status}`}>{stop.stopNumber}</div>
        )}
      </div>
      <div className="description">
        <div className="name">{shipment.order.recipient}</div>
        <div className="address">{shipment.order.address}</div>
      </div>
      {showMore &&
        <div className="more-info">
          <div className="field">
            <div className="name">{intl.formatMessage({id: 'GLOBAL.EMAIL'})}</div>
            <div className="value">{shipment.order.email || '-'}</div>
          </div>
          <div className="field">
            <div className="name">{intl.formatMessage({id: 'GLOBAL.PHONE_NUMBER'})}</div>
            <div className="value">{shipment.order.phone || '-'}</div>
          </div>
          <div className="field full">
            <div className="name">{intl.formatMessage({id: 'DELIVERY.CUSTOMER_NOTE'})}</div>
            <div className="value">{shipment.order.note || '-'}</div>
          </div>
        </div>
      }
    </div>
  )
}

export default withShipment(DriverAppShipment);
