import React from "react";
import { connect } from "react-redux";
import { doDetail, doStartRoute } from "../index";
import { State, DriverRoute } from "../types";
import { routeSelector } from '../selectors';

export type Props = {
  id?: string
  request: any
  route?: DriverRoute
  doDetail(_: any): void
  doStartRoute(_: any): void
}

export default <T extends Props>(Component: React.ComponentType<T>) => {
  class Container extends React.Component<Props> {
    render() {
      return <Component {...this.props as T} />;
    }
  }
  function mapStateToProps({ driverApp }: { driverApp: State }) {
    return {
      request: driverApp.request.today || {},
      route: routeSelector(driverApp, 'today')
    }
  }
  return connect(
    mapStateToProps,
    {
      doDetail,
      doStartRoute
    }
  )(Container);
}