import { Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { PasswordForm, EmailAddressForm } from '../forms';
import withPage, { PageProps } from './withPage';
import withAction, { ActionProps } from '../../modules/auth/hocs/withAction';
import { useIntl } from "react-intl";
import { LanguageSelectInput } from '../inputs';

type Props = PageProps & ActionProps;

const ResetPasswordPage: React.FC<Props> = ({
  request,
  doResetPassword,
  doChangePassword,
  query
}) => {
  const intl = useIntl();
  const appSchema = query.app_schema;
  useEffect(() => {
    if (appSchema && query.token) {
      window.location.assign(appSchema+`?token=${query.token}`);
    }
  }, [appSchema])
  return (
    <div className="ResetPasswordPage" style={{padding: 20}}>
      <br />
      <br />
      <Row>
        <Col md={{span: 6, offset: 9}} sm={{span: 12, offset: 6}} xs={24}>
          <div className="envoi-logo">
            <img src="/logo.svg" alt="logo" />
          </div>
          <Typography.Title level={3}>{intl.formatMessage({id: query.token ? 'AUTH.UPDATE_NEW_PASSWORD' : 'AUTH.FORGET_PASSWORD'})}</Typography.Title>
          {
            query.token
            ? <PasswordForm
                loading={request.isFetching}
                onSubmit={values => doChangePassword({...values, token: query.token})}
              />
            : <EmailAddressForm
                loading={request.isFetching}
                onSubmit={doResetPassword}
              />
          }
        </Col>
      </Row>
      <LanguageSelectInput/>
    </div>
  )
}

export default withPage(withAction(ResetPasswordPage));
