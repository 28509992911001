import React, { useState } from 'react';
import { DEFAULT_LOCALE, LOCALES } from '../../constants';
import {IntlProvider} from 'react-intl';
import fr from 'antd/lib/locale/fr_FR';
import en from 'antd/lib/locale/en_US';

export const LANGUAGES: any = {
  'en': {name: 'English', code: 'en'},
  'fr': {name: 'Français', code: 'fr'}
}

const flattenMessages = ((nestedMessages: any, prefix = '') => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
})

export type ContextProps = {
  language?: string
  getAntdLocale?(): any
  changeLanguage?(language: string): void
}

const Context = React.createContext<ContextProps>({ language: DEFAULT_LOCALE });
const messages: any = LOCALES;

const Locale: React.FC = ({children}) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [language, setLang] = useState<string>(params.locale || navigator?.language?.slice(0,2) || DEFAULT_LOCALE);
  Object.keys(messages).forEach((key: any) => {
    messages[key] = flattenMessages(messages[key]);
  })

  const getAntdLocale = () => {
    switch (language) {
      case 'en':
        return en;
      case 'fr':
        return fr;
      default:
        return en;
    }
  }

  const handleChangeLanguage = (language: string) => {
    if (Object.keys(LANGUAGES).includes(language)) {
      setLang(language);
    }
    else {
      setLang(DEFAULT_LOCALE);
    }
  }

  return (
    <Context.Provider
      value={{
        language,
        getAntdLocale,
        changeLanguage: handleChangeLanguage
      }}
    >
      <IntlProvider
        locale={language}
        messages={messages[language]}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export const LocaleContextProvider = Locale;
export const LocaleContext = Context;
export const LocaleContextConsumer = Context.Consumer;
