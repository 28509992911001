import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { moduleOutput, toCamelKey } from 'react-easy-module/es/module';
import { AUTH_KEY } from '../../constants';
import { Account } from '../account/types';
import { State } from './types';
import * as api from './api';
import { isDriver } from '../account/helpers';

function parseToken(token?: string): Account | undefined {
  if (!token) return undefined;
  let account: Account | undefined;
  try {
    account = jwtDecode(token);
  } catch (e) {
    console.log(e);
  }
  return account;
}

const REGISTER_ACCOUNT = 'REGISTER_ACCOUNT';
const LOGIN_SESSION = 'LOGIN_SESSION';
const LOGOUT_SESSION = 'LOGOUT_SESSION';
const REFRESH_SESSION = 'REFRESH_SESSION';
const RESET_PASSWORD = 'RESET_PASSWORD';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

const initState: State = {
  request: {},
  account: parseToken(Cookies.get(AUTH_KEY)),
  authenticated: !!Cookies.get(AUTH_KEY)
}
const inputs = [
  {
    action: LOGIN_SESSION,
    apiPayload(payload: any) {
      return {
        api: api.signIn,
        params: payload,
        next: '/'
      }
    },
    onSuccess(state: any, payload: any) {
      const { response } = payload;
      const account = parseToken(response.data.token);
      if (account && !isDriver(account)) return state;
      Cookies.set(AUTH_KEY, response.data.token);
      return {
        ...state,
        account,
        authenticated: true
      };
    }
  },
  {
    action: REFRESH_SESSION,
    apiPayload(payload: any) {
      return {
        api: api.refreshSession,
        params: payload
      }
    },
    onSuccess(state: any, payload: any) {
      return {
        ...state,
        account: payload.response.data
      }
    },
    onFailure(state: any, _: any) {
      return {
        ...state,
        account: null,
        authenticated: false
      }
    }
  },
  {
    action: LOGOUT_SESSION,
    apiPayload(payload: any) {
      return {
        api: () => Promise.resolve({response: {}}),
        params: payload
      }
    },
    onSuccess(state: any, _: any) {
      Cookies.remove(AUTH_KEY);
      return {
        ...state,
        account: null,
        authenticated: false
      }
    }
  },
  {
    action: REGISTER_ACCOUNT,
    apiPayload(payload: any) {
      return {
        api: api.signUp,
        params: payload,
        next: '/sign-up?success=true'
      }
    }
  },
  {
    action: RESET_PASSWORD,
    apiPayload(payload: any) {
      return {
        api: api.resetPassword,
        params: payload,
        next: '/login'
      }
    }
  },
  {
    action: CHANGE_PASSWORD,
    apiPayload(payload: any) {
      return {
        api: api.updatePassword,
        params: payload,
        next: '/'
      }
    },
    onSuccess(state: any, payload: any) {
      const { response } = payload;
      Cookies.set(AUTH_KEY, response.data.token);
      return {
        ...state,
        account: parseToken(response.data.token),
        authenticated: true
      };
    }
  }
]

const {
  actions,
  reducer,
  sagas
} = moduleOutput(inputs)(initState);

export const doRegisterAccount = actions[toCamelKey(`DO_${REGISTER_ACCOUNT}`)];
export const doLoginSession = actions[toCamelKey(`DO_${LOGIN_SESSION}`)];
export const doRefreshSession = actions[toCamelKey(`DO_${REFRESH_SESSION}`)];
export const doLogoutSession = actions[toCamelKey(`DO_${LOGOUT_SESSION}`)];
export const doResetPassword = actions[toCamelKey(`DO_${RESET_PASSWORD}`)];
export const doChangePassword = actions[toCamelKey(`DO_${CHANGE_PASSWORD}`)];
export const authReducer = { auth: reducer };
export const authSagas = sagas;
