import React from 'react';
import { Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { useIntl } from "react-intl";
import './File.scss';

const { Dragger } = Upload;

function humanFileSize(size: number) {
  var i = Math.floor( Math.log(size) / Math.log(1024) );
  return ( size / Math.pow(1024, i) ).toFixed(2) + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

const FileInput: React.FC<UploadProps> = props => {
  const intl = useIntl();
  const file = (props.id && (props as any)[props.id]) || undefined;
  function getFileName() {
    if (typeof file === 'string') {
      return file;
    }
    return file.file?.name;
  }
  function getFileSize() {
    if (!file.file?.size) return;
    return humanFileSize(file.file?.size);
  }
  return (
    <>
      <Dragger
        className="FileInput"
        showUploadList={false}
        beforeUpload={() => false}
        multiple={false}
        {...props}
      >
        <div className="hint">{intl.formatMessage({id: 'ALERT.DROP_FILE'})}</div>
      </Dragger>
      {file &&
        <div className="preview-uploaded-file">
          {getFileName()} ({getFileSize()})
        </div>
      }
    </>
  )
}

export default FileInput;
