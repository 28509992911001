import React from 'react';
import { SignInForm } from '../forms';
import withAuth, { AuthProps } from '../../modules/auth/hocs/withAuth';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { useIntl } from "react-intl";
import { LanguageSelectInput } from '../inputs';
import './Login.scss';

const LoginPage: React.FC<AuthProps> = ({
  isFetching,
  doLoginSession,
}) => {
  const intl = useIntl();
  return(
    <div className="LoginPage">
      <br />
      <br />
      <Row>
        <Col md={{span: 6, offset: 9}} sm={{span: 12, offset: 6}} xs={24}>
          <div className="envoi-logo">
            <img src="/logo.svg" alt="logo" />
          </div>
          <SignInForm
            loading={isFetching}
            onSubmit={doLoginSession}
          />
          <Link to="/reset-password">
            {intl.formatMessage({id: 'AUTH.FORGET_PASSWORD'})}
          </Link>
        </Col>
      </Row>
      <LanguageSelectInput/>
    </div>
  )
}

export default withAuth(LoginPage);
