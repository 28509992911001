import React from 'react';
import { Col, Row, Result, Button } from 'antd';
import { DriverSignUpForm } from '../forms';
import withPage, { PageProps } from './withPage';
import withAction, { ActionProps } from '../../modules/auth/hocs/withAction';
import { ROLES } from '../../modules/role/types';
import { useIntl } from "react-intl";
import { LanguageSelectInput } from '../inputs';
import './DriverSignUp.scss'

type Props = PageProps & ActionProps;

const DriverSignUpPage: React.FC<Props> = ({
  request,
  doRegisterAccount,
  query
}) => {
  const intl = useIntl();
  const handleDriverSignUpFormSubmit = (values: any) => {
    doRegisterAccount({...values, role: ROLES.DRIVER});
  }
  const isSuccess = query.success === 'true';
  return (
    <div className="DriverSignUpPage" style={{padding: 20}}>
      <br />
      <Row>
        <Col md={isSuccess ? {span: 12, offset: 6} : {span: 6, offset: 9} } sm={{span: 12, offset: 6}} xs={24}>
          <div className="envoi-logo">
            <img src="/logo.svg" alt="logo" />
          </div>
          {isSuccess &&
            <Result
              icon={<img src="/delivery-patrick.png" alt="delivery-patrick" />}
              status="success"
              title={intl.formatMessage({id: 'AUTH.SIGNUP_SUCCESS_INFO'})}
              extra={
                <div>
                  <div>
                    <a href="https://calendly.com/envoi-hiring">
                      <Button>{intl.formatMessage({id: 'AUTH.BOOK_INTERVIEW'})}</Button>
                    </a>
                  </div>
                  <div>
                    <a className='appleAnchor' href="https://apps.apple.com/ca/app/envoi-driver/id1479945768">
                      <img className='appleImage' src="https://envoi-emails.s3.ca-central-1.amazonaws.com/apple-app-store-badge.png" alt="Download on the App Store"/>
                    </a>
                    <a className='googleAnchor' href="https://play.google.com/store/apps/details?id=com.envoinow.driver&hl=en_CA&gl=US">
                      <img className='googleImage' src="https://envoi-emails.s3.ca-central-1.amazonaws.com/google-play-badge.png" alt="Get it on Google Play" />
                    </a>
                  </div>
                  <div className='footer'>
                    <p>Play logo are trademarks of Google LLC. Apple logo and App Store® logo are trademarks of Apple Inc.</p>
                  </div>
                </div>
                
              }
            />
          }
          {!isSuccess &&
            <DriverSignUpForm
              email={query.email}
              referrerCode={query.referrerCode}
              loading={request.isFetching}
              onSubmit={handleDriverSignUpFormSubmit}
            />
          }
        </Col>
      </Row>
      <LanguageSelectInput/>
    </div>
  )
}

export default withPage(withAction(DriverSignUpPage));
