import React, { ReactNode } from 'react';
import { Form, Calendar, Input, Row, Col, Select, DatePicker, TimePicker, Checkbox, Slider, InputNumber, Switch, Radio } from 'antd';
import moment from 'moment';
import { FormItemProps } from 'antd/lib/form';
import { FileInput, AddressInput, PhotoUploadInput } from '../../inputs';
import { BACKEND_TIME_FORMAT, FRONTEND_DATE_FORMAT, STRONG_PASSWORD_REGEX } from '../../../constants';
import { useIntl } from "react-intl";

export enum ITEM_TYPE {
  EMAIL,
  PASSWORD,
  SELECT,
  FILE,
  DATE,
  TIME,
  CHECKBOX,
  TEXTAREA,
  ADDRESS,
  TIME_RANGE,
  UPLOAD_PHOTO,
  CHECKBOX_GROUP,
  SLIDER,
  NUMBER,
  CALENDAR,
  DATE_RANGE,
  SWITCH,
  RADIO_GROUP
};

type OptionProps = {
  value: number | string
  label?: string
  raw?: any
}

type ItemProps = {
  required?: boolean
  type?: ITEM_TYPE
  col?: number | {xs?: number, sm?: number, md?: number, lg?: number, xl?: number, xxl?: number}
  options?: OptionProps[]
  node?: ReactNode
  formItemProps: FormItemProps
  inputProps?: any
}

export type ItemsProps = ItemProps | ItemProps[];

function disabledDate(current: any) {
  // Can not select days before today and today
  return current && current < moment().startOf('day');
}

export default (items: ItemsProps[]) => {
  const intl = useIntl();
  const getItemRules = (item: ItemProps): any => {
    const rules = item.formItemProps.rules || [];
    if (item.required) {
      rules.push({ required: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'}) });
    }
    if (item.type === ITEM_TYPE.EMAIL) {
      rules.push({ type: 'email', message: intl.formatMessage({id: 'VALIDATION.INVALID_EMAIL'}) });
    }
    if (item.required && item.type === ITEM_TYPE.PASSWORD) {
      rules.push({ pattern: STRONG_PASSWORD_REGEX, message:  intl.formatMessage({id: 'VALIDATION.STRONG_PASSWORD'}) })
    }
    return rules;
  }
  const getItemInput = (item: ItemProps) => {
    if (item.type === ITEM_TYPE.PASSWORD) {
      return <Input.Password autoComplete="off" {...item.inputProps} />
    }
    if (item.type === ITEM_TYPE.SELECT) {
      return (
        <Select
          listHeight={window.innerHeight}
          {...item.inputProps}
        >
          {item.options?.map(option =>
            <Select.Option key={option.value} value={option.value} raw={option.raw}>{option.label || option.value}</Select.Option>
          )}
        </Select>
      )
    }
    if (item.type === ITEM_TYPE.FILE) {
      return <FileInput {...item.inputProps} />
    }
    if (item.type === ITEM_TYPE.DATE) {
      return <DatePicker format={FRONTEND_DATE_FORMAT} disabledDate={disabledDate} {...item.inputProps}/>
    }
    if (item.type === ITEM_TYPE.CHECKBOX) {
      return <Checkbox {...item.inputProps} >{item.inputProps?.label}</Checkbox>
    }
    if (item.type === ITEM_TYPE.ADDRESS) {
      return <AddressInput {...item.inputProps}/>
    }
    if (item.type === ITEM_TYPE.TEXTAREA) {
      return <Input.TextArea autoComplete="off" autoSize={{ minRows: 1}} {...item.inputProps}/>
    }
    if (item.type === ITEM_TYPE.TIME) {
      return <TimePicker {...item.inputProps} format={BACKEND_TIME_FORMAT} minuteStep={5}/>
    }
    if (item.type === ITEM_TYPE.TIME_RANGE) {
      return <TimePicker.RangePicker {...item.inputProps} format={BACKEND_TIME_FORMAT} minuteStep={5}/>
    }
    if (item.type === ITEM_TYPE.UPLOAD_PHOTO) {
      return <PhotoUploadInput {...item.inputProps} />
    }
    if (item.type === ITEM_TYPE.CHECKBOX_GROUP) {
      return <Checkbox.Group {...item.inputProps} />
    }
    if (item.type === ITEM_TYPE.SLIDER) {
      return <Slider {...item.inputProps} />
    }
    if (item.type === ITEM_TYPE.NUMBER) {
      return <InputNumber autoComplete="off" {...item.inputProps} />
    }
    if (item.type === ITEM_TYPE.CALENDAR) {
      return <Calendar {...item.inputProps} />
    }
    if (item.type === ITEM_TYPE.DATE_RANGE) {
      return <DatePicker.RangePicker {...item.inputProps} />
    }
    if (item.type === ITEM_TYPE.SWITCH) {
      return <Switch {...item.inputProps} />
    }
    if (item.type === ITEM_TYPE.RADIO_GROUP) {
      return (
        <Radio.Group
          {...item.inputProps}
        >
          {item.options?.map(option =>
            <Radio key={option.value} value={option.value}>{option.label || option.value}</Radio>
          )}
        </Radio.Group>
      )
    }
    return <Input autoComplete="off" {...item.inputProps} />
  }
  const renderItem = (item: ItemProps, index?: number) => (
    <Col
      {...(typeof item.col === 'object' ? item.col : undefined)}
      span={ typeof item.col === 'number' ? item.col : undefined}
      key={`item-${index}`}
    >
      <Form.Item
        {...item.formItemProps}
        rules={getItemRules(item)}
      >
        {item.node ? item.node : getItemInput(item)}
      </Form.Item>
    </Col>
  )
  const renderItems = (items: ItemsProps, index?: number) => {
    if (Array.isArray(items)) {
      return (
        <Row gutter={16} key={`group-${index}`}>
          {(items as ItemProps[]).map(renderItem)}
        </Row>
      );
    }
    return renderItem(items as ItemProps, index);
  }
  return items.map(renderItems);
}
