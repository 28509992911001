import { Row, Col, Button, Upload, Modal, Radio, Input, message } from 'antd';
import React, { useState } from 'react';
import { Shipment, SHIPMENT_STATUS } from '../../modules/shipment/types';
import { PlusOutlined } from '@ant-design/icons';
import './DriverShipment.scss';
import { UploadChangeParam } from 'antd/lib/upload';
import { COMPLETE_TYPE, SKIP_REASON, REASON_IDS_REQUIRED_POD } from '../../constants';
import { EditableInput } from '../inputs';
import { useIntl } from "react-intl";

const PREVIEW_IMAGE = 'PREVIEW_IMAGE';

type Props = {
  shipment: Shipment
  disabled?: boolean
  onCancel?(): void
  onSubmit?(_:any): void
}

type ModalState = {
  visible?: boolean;
  type?: string;
  previewImage?: string;
  statusId?: string | number;
  fileList?: any[];
}

type State = {
  status?: string;
  statusId?: string | number;
  statusNote?: string;
  driverNote?: string;
}

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const DriverShipmentForm: React.FC<Props> = ({
  shipment,
  disabled,
  onCancel,
  onSubmit
}) => {
  const intl = useIntl();
  const [modalState, setModalState] = useState<ModalState>({
    fileList: (shipment.photos||[]).map((url, i) => ({
      uid: `-${i+1}`,
      name: 'image.jpg',
      status: 'done',
      url
    }))
  });
  const [state, setState] = useState<State>({
    status: shipment.status,
    statusId: shipment.statusId,
    statusNote: shipment.statusNote,
    driverNote: shipment.driverNote
  });
  const isCompleted = state.status === SHIPMENT_STATUS.COMPLETED;
  const isSkipped = state.status === SHIPMENT_STATUS.SKIPPED;
  const handleCompleteBtnClick = () => {
    setModalState({...modalState, visible: true, type: SHIPMENT_STATUS.COMPLETED});
  }
  const handleSkipBtnClick = () => {
    setModalState({...modalState, visible: true, type: SHIPMENT_STATUS.SKIPPED});
  }
  const handleCompleteOptionClick = (id: string) => {
    setState({...state, status: SHIPMENT_STATUS.COMPLETED, statusId: id});
    setModalState({...modalState, visible: false});
  }
  const handleSkipOptionClick = (e: any) => {
    if (e.target.value === "0") {
      setModalState({...modalState, statusId: "0"});
    } else {
      setState({...state, status: SHIPMENT_STATUS.SKIPPED, statusId: e.target.value});
      setModalState({...modalState, visible: false});
    }
  }
  const handleSkipInputSave = (value: string) => {
    setState({...state, status: SHIPMENT_STATUS.SKIPPED, statusId: "0", statusNote: value});
    setModalState({...modalState, visible: false});
  }
  const handlePhotosChange = ({fileList}: UploadChangeParam) => {
    setModalState({...state, fileList })
  }
  const handleNotechange = (e: any) => {
    setState({...state, driverNote: e.target.value});
  }
  const handleModalCancel = () => {
    setModalState({...modalState, visible: false});
  }
  const handlePreviewImage = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setModalState({
      ...modalState,
      visible: true,
      type: PREVIEW_IMAGE,
      previewImage: file.url || file.preview
    });
  }
  const handleSubmitClick = () => {
    const { status, statusId, statusNote, driverNote } = state;
    const photos = modalState.fileList?.map(f => f.url || f.originFileObj) || [];
    const photoTotal = photos.length;
    let error;
    if (!status || !statusId) error = intl.formatMessage({id: 'ERROR.UPDATE_DELIVERY_STATUS'});
    if (
      (isCompleted && photoTotal === 0) ||
      (isSkipped && REASON_IDS_REQUIRED_POD.includes(Number(statusId)) && photoTotal === 0)
    ) error = intl.formatMessage({id: 'ERROR.ADD_POD_PHOTO'});
    if (error) {
      return message.error(error);
    }
    onSubmit && onSubmit({status, statusId, photos, statusNote, driverNote});
  }
  const changed = shipment.status !== state.status ||
    shipment.statusId !== state.statusId ||
    shipment.statusNote !== state.statusNote ||
    shipment.driverNote !== state.driverNote ||
    (shipment.photos||[]).length !== (modalState.fileList||[]).length;
  return (
    <div className="DriverShipmentForm">
      <div className="section">
        <div className="name">{intl.formatMessage({id: 'DELIVERY.STATUS'})}</div>
        <Button
          block
          size="large"
          disabled={disabled}
          onClick={handleCompleteBtnClick}
          type={isCompleted ? 'primary' : undefined}
          hidden={disabled && !isCompleted}
        >
          {intl.formatMessage({id: 'ACTION.COMPLETE'})}
        </Button>
        <div className="hint">
          {state.statusId !== undefined && isCompleted && !!state?.statusId && intl.formatMessage({id: `COMPLETE_TYPE.${state?.statusId}`}) }
        </div>
        <Button
          block
          size="large"
          disabled={disabled}
          onClick={handleSkipBtnClick}
          type={isSkipped ? 'primary' : undefined}
          danger={isSkipped}
          hidden={disabled && !isSkipped}
        >
          {intl.formatMessage({id: 'ACTION.SKIP'})}
        </Button>
        <div className="hint">
          {(state.statusId !== undefined) && isSkipped && ((state?.statusId && intl.formatMessage({id: `SKIP_REASON.${state?.statusId}`})) || state.statusNote)}
        </div>
      </div>
      <div className="section">
        <div className="name">{intl.formatMessage({id: 'DELIVERY.DRIVER_NOTE'})}</div>
        <Input.TextArea
          rows={5}
          placeholder={intl.formatMessage({id: 'ALERT.TYPE_SOMETHING'})}
          disabled={disabled}
          value={state.driverNote}
          onChange={handleNotechange}
        />
      </div>
      <div className="section">
        <div className="name">{intl.formatMessage({id: 'DELIVERY.POD_PHOTO'})}</div>
        <Upload
          listType="picture-card"
          disabled={disabled}
          accept="image/*"
          fileList={modalState.fileList}
          onPreview={handlePreviewImage}
          beforeUpload={() => false}
          onChange={handlePhotosChange}
        >
          {!disabled && <PlusOutlined />}
        </Upload>
      </div>
      <div className="action">
        <Row gutter={2}>
          <Col span={12}>
            <Button
              block
              size="large"
              onClick={onCancel}
            >
              {intl.formatMessage({id: 'ACTION.CANCEL'})}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              size="large"
              block
              disabled={disabled || !changed}
              onClick={handleSubmitClick}
            >
              {intl.formatMessage({id: 'ACTION.SAVE'})}
            </Button>
          </Col>
        </Row>
      </div>
      <Modal
        visible={modalState.visible}
        footer={null}
        onCancel={handleModalCancel}
      >
        <div className="driver-app-modal-body">
          {modalState.type === SHIPMENT_STATUS.COMPLETED &&
            <div className="complete-options">
              {Object.keys(COMPLETE_TYPE).map(key =>
                <Button
                  key={key}
                  size="large"
                  block
                  onClick={() => handleCompleteOptionClick(key)}
                >
                  {intl.formatMessage({id: `COMPLETE_TYPE.${key}`})}
                </Button>
              )}
            </div>
          }
          {modalState.type === SHIPMENT_STATUS.SKIPPED &&
            <div className="skip-options">
              <Radio.Group value={modalState.statusId === "0" ? modalState.statusId : state.statusId} onChange={handleSkipOptionClick}>
                {Object.keys(SKIP_REASON).map(key =>
                  <Radio key={key} value={key}>{intl.formatMessage({id: `SKIP_REASON.${key}`})}</Radio>
                )}
                <Radio value="0">
                  <EditableInput
                    value={state.statusNote || intl.formatMessage({id: `SKIP_REASON.0`})}
                    visible={modalState.statusId === "0"}
                    onCancel={handleModalCancel}
                    onSubmit={handleSkipInputSave}
                  />
                </Radio>
              </Radio.Group>
            </div>
          }
        </div>
        {modalState.type === PREVIEW_IMAGE &&
          <img alt="example" style={{ width: '100%' }} src={modalState.previewImage} />
        }
      </Modal>
    </div>
  )
}

export default DriverShipmentForm;
