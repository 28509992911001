import * as api from './api'
import { State } from './types'
import { moduleOutput, toCamelKey } from 'react-easy-module/es/module'

const DETAIL_DRIVER_APPLICATION = 'DETAIL_DRIVER_APPLICATION';
const UPDATE_DRIVER_APPLICATION = 'UPDATE_DRIVER_APPLICATION';

const initState: State = {
  request: {},
  items: {},
  metadata: {
    current: 1,
    pageSize: 10,
    total: 0,
    ids: []
  }
}

const inputs = [
  {
    action: DETAIL_DRIVER_APPLICATION,
    apiPayload(payload: any) {
      return {
        api: api.detail,
        params: payload
      }
    }
  },
  {
    action: UPDATE_DRIVER_APPLICATION,
    apiPayload(payload: any) {
      const { stepIndex, ...params } = payload;
      return {
        api: api.update,
        params,
        next() {
          return `/application?step=${stepIndex+1}`
        }
      }
    }
  }
]

const {
  actions,
  reducer,
  sagas
} = moduleOutput(inputs)(initState);

export const doDetail = actions[toCamelKey(`DO_${DETAIL_DRIVER_APPLICATION}`)];
export const doUpdate = actions[toCamelKey(`DO_${UPDATE_DRIVER_APPLICATION}`)];
export const driverApplicationReducer = { driverApplication: reducer };
export const driverApplicationSagas = sagas;