import React, { ReactNode } from 'react';
import { LeftOutlined } from '@ant-design/icons'
import './Head.scss';
import { Button } from 'antd';

type Props = {
  title?: ReactNode
  onBack?(): void
}

const DriverAppHead: React.FC<Props> = ({
  title,
  onBack
}) => (
  <div className="DriverAppHead">
    {onBack &&
      <Button
        className="left"
        type="link"
        icon={<LeftOutlined />}
        onClick={onBack}
      />
    }
    <div className="title">{title}</div>
  </div>
)

export default DriverAppHead;
