import { Button } from 'antd';
import React from 'react';
import { PickupPoint } from '../../../modules/pickup-point/types';
import { useIntl } from "react-intl";
import './StartRoute.scss';

type Props = {
  pickupPoint: PickupPoint
  onStartRoute?(): void
}

const DriverAppStartRoute: React.FC<Props> = ({
  pickupPoint,
  onStartRoute
}) => {
  const intl = useIntl();
  return (
    <div className="DriverAppStartRoute">
      <div className="name">{pickupPoint.name}</div>
      <div className="address">{pickupPoint.address}</div>
      <Button type="primary" block onClick={onStartRoute}>{intl.formatMessage({id: 'ACTION.START_ROUTE'})}</Button>
    </div>
  );
}

export default DriverAppStartRoute;
