import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { State } from '../types';
import { DRIVER_STATUS } from '../../driver/types';

type Props = RouteProps & {
  auth: State
}

class Container extends Component<Props> {
  render() {
    const { auth, component: Comp, ...props } = this.props;
    const account = auth.account;
    const isApproved = account?.status === DRIVER_STATUS.APPROVED;
    const routeRender = (p: any) => {
      if (!auth || !auth.authenticated || !Comp) {
        return <Redirect to={{ pathname: "/login", state: { from: p.location } }} />
      }
      if (isApproved && (props.location?.pathname === "/" || props.location?.pathname === "/application")) {
        return <Redirect to={{ pathname: "/shipments", state: { from: p.location } }} />
      }
      if (!isApproved && props.location?.pathname !== "/application") {
        return <Redirect to={{ pathname: "/application", state: { from: p.location } }} />
      }
      return <Comp {...p} />;
    }
    return (
      <Route {...props} render={routeRender} />
    );
  }
}

export default connect((state: { auth: State}) => ({
  auth: state.auth
}))(Container);
