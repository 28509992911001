import React from 'react';
import {
  Button
} from 'antd';
import useItems, { ITEM_TYPE } from './hooks/useItems';
import BaseForm, { BaseFormProps } from './Base';
import { useIntl } from "react-intl";

type Props = BaseFormProps

const SignInForm: React.FC<Props> = ({
  loading,
  ...props
}) => {
  const intl = useIntl();
  return (
    <BaseForm
      {...props}
      submitBtnVisible={false}
    >
      {useItems([
        {formItemProps: {name: 'email', label: intl.formatMessage({id: 'GLOBAL.EMAIL'})}, inputProps: { size: 'large' }, required: true},
        {formItemProps: {name: 'password', label: intl.formatMessage({id: 'GLOBAL.PASSWORD'})}, inputProps: { size: 'large' }, type: ITEM_TYPE.PASSWORD},
        {
          formItemProps: {name: 'remember', valuePropName: 'checked'},
          inputProps: { label: intl.formatMessage({id: 'AUTH.REMEMBER_ME'}) },
          type: ITEM_TYPE.CHECKBOX,
          col: 12
        }
      ])}
      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        block
        size="large"
      >
        {intl.formatMessage({id: 'ACTION.LOGIN'})}
      </Button>
    </BaseForm>
  )
}

export default SignInForm;
