import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

const E404Page: React.FC = () => {
  const intl = useIntl();
  return (
    <Result
      status="404"
      title="404"
      subTitle={intl.formatMessage({id: 'ERROR.PAGE_NOT_EXIST'})}
      extra={
        <Link to="/">
          <Button type="primary">{intl.formatMessage({id: 'ACTION.BACK_HOME'})}</Button>
        </Link>
      }
    />
  );
}

export default E404Page;
