import React, { useEffect } from 'react';
import withPage, { PageProps } from '../withPage';
import withShipment, { Props as ShipmentProps } from '../../../modules/driver-app/hocs/withShipment';
import { AppHead, ShipmentView } from '../../views/driver-app';
import { DriverShipmentForm } from '../../forms';
import { SHIPMENT_STATUS } from '../../../modules/shipment/types';
import { Spin } from 'antd';
import { useIntl } from "react-intl";

type Props = PageProps & ShipmentProps;

const DriverAppShipmentPage: React.FC<Props> = ({
  id,
  request,
  shipment,
  doDetail,
  doUpdateShipmentStatus,
  history
}) => {
  const intl = useIntl();
  const mpsStopsIds = shipment?.mpsStops?.filter(s => Number(s.id) !== Number(shipment.id)).map(s => s.id) || [];
  useEffect(() => {
    !shipment && history.goBack();
    // eslint-disable-next-line
  },[])
  const handleSubmit = (values: any) => {
    let backToList = mpsStopsIds.length === 0;
    doUpdateShipmentStatus({
      id: shipment?.id,
      date: shipment?.date,
      stopNumber: shipment?.stopNumber,
      ...values,
      backToList
    });
    for (let i = 0; i < mpsStopsIds.length; i++) {
      const shipmentId = mpsStopsIds[i];
      backToList = (mpsStopsIds.length - 1) === i;
      doUpdateShipmentStatus({
        id: shipmentId,
        date: shipment?.date,
        ...values,
        backToList
      });
    }
  }
  const inProgress = shipment?.status === SHIPMENT_STATUS.ATTEMPTING_DELIVERY;
  const finished = shipment?.status === SHIPMENT_STATUS.COMPLETED || shipment?.status === SHIPMENT_STATUS.SKIPPED;
  return (
    <Spin spinning={!!request.isFetching}>
      <div className="DriverAppShipmentPage" style={{marginBottom: 50, marginTop: 56}}>
        <AppHead
          onBack={() => history.goBack()}
          title={intl.formatMessage({id: 'DELIVERY.DETAILS'})}
        />
        {!shipment &&
          <div>{intl.formatMessage({id: 'ALERT.SHIPMENT_NOT_FOUND'})}</div>
        }
        <ShipmentView id={id} showMore={true} />
        {shipment && (finished || inProgress) &&
          <DriverShipmentForm
            shipment={shipment}
            onSubmit={handleSubmit}
            onCancel={() => history.goBack()}
          />
        }
      </div>
    </Spin>
  )
}

export default withPage(withShipment(DriverAppShipmentPage));
