import { IModuleState } from "react-easy-module/es/module";
import { Driver } from '../driver/types';
import { Shipment } from '../shipment/types';
import { PickupPoint } from '../pickup-point/types';

export enum ROUTE_STATUS {
  PLANNING = 'PLANNING',
  SORTING = 'SORTING',
  DISPATCHED = 'DISPATCHED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED'
}

export type ShipmentRoute = {
  id: string;
  date: string;
  time: string;
  etd?: string;
  eta?: string;
  status?: ROUTE_STATUS;
  driver?: Driver;
  driverId?: string;
  driverName?: string;
  startedAt?: string;
  finishedAt?: string;
  pickupPoint?: PickupPoint;
  pickupPointId?: number;
  polylines?: number[][];
  modified?: boolean
}

export type Metadata = {
  current: number;
  pageSize: number;
  total: number;
  ids: string[]
}

export type State = IModuleState & {
  items: { [key: string]: ShipmentRoute }
  metadata: Metadata
}
