import request from '../../utils/request';
import Compressor from 'compressorjs';

export const URL_REGEX = /http(s)?:\/\//i;

export function uploadFileToGcs(
  params: {filePath: string, file: any, fileType: string, privated?: boolean}
) {
  const { file, filePath, fileType, privated } = params;
  return new Promise<{response?: any, error?: any}>(resolve => {
    if (typeof file === 'string' && URL_REGEX.test(file)) return resolve({response: file});
    new Compressor(file, {
      maxWidth: 512,
      maxHeight: 512*2,
      success: (compressed) => {
        getUploadSignedUrl({ file: filePath, fileType, privated }).then(r1 => {
          if (r1.error) return resolve(r1);
          const signedLink = r1.response.data;
          const url = signedLink.split('?')[0];
          uploadFileToLink({ url: signedLink, file: compressed, fileType }).then(r2 => {
            if (r2.error) return resolve(r2);
            resolve({response: url});
          })
        })
      },
      error: (e) => {
        resolve({ error: { message: e.message }})
      }
    });
  })
}

export function getUploadSignedUrl(params: any) {
  return request('/uploads/signed-url', {
    method: 'GET',
    params
  });
}

export function uploadFileToLink({url, file, fileType}: any) {
  return request(url, {
    method: 'PUT',
    headers: {
      'Content-Type': fileType
    },
    data: file
  })
}