import React, { useEffect } from 'react';
import withRoute, { Props as RouteProps } from '../../../modules/driver-app/hocs/withRoute';
import withPage, { PageProps } from '../withPage';
import { ShipmentsView, AppHead, AppFoot, StartRouteView } from '../../views/driver-app';
import { NAV_TAB } from '../../views/driver-app/Foot';
import { ROUTE_STATUS } from '../../../modules/shipment-route/types';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { BACKEND_DATE_FORMAT } from '../../../constants';

type Props = PageProps & RouteProps;


const DriverAppShipmentsPage: React.FC<Props> = ({
  route,
  pushTo,
  doStartRoute,
  doDetail,
  query
}) => {
  const today = moment().format(BACKEND_DATE_FORMAT);
  const currentDate = query.date || today;
  useEffect(() => {
    handleFilter({date: currentDate}, true);
    // eslint-disable-next-line
  }, [route?.id]);
  function handleFilter(params?: any, updatePath?: boolean) {
    if (updatePath) {
      pushTo({query: params});
    }
    doDetail({...params, id: 'today'});
  }
  const handleTabClick = (tab: NAV_TAB) => {
    if (tab === NAV_TAB.SHIPMENTS) {
      pushTo({path: "/shipments"});
    }
    if (tab === NAV_TAB.PROFILE) {
      pushTo({path: "/profile"});
    }
  }
  const handleDateChange = (date: Moment | null) => {
    if (date) {
      handleFilter({date: date.format(BACKEND_DATE_FORMAT)}, true);
    }
  }
  const handleOnStartRoute = () => {
    doStartRoute({id: route?.id});
  }
  const dispatched = route?.status === ROUTE_STATUS.DISPATCHED;
  return (
    <div className="DriverAppShipmentsPage" style={{marginBottom: 100, marginTop: 56}}>
      <AppHead
        title={
          <DatePicker
            allowClear={false}
            value={moment(currentDate, BACKEND_DATE_FORMAT)}
            onChange={handleDateChange}
          />
        }
      />
      {dispatched && route?.pickupPoint &&
        <StartRouteView pickupPoint={route?.pickupPoint} onStartRoute={handleOnStartRoute}/>
      }
      <ShipmentsView
        shipmentIds={route?.shipmentIds || []}
        onShipmentClick={id => pushTo({path: `/shipments/${id}`})}
        disabled={dispatched}
      />
      <AppFoot
        onTabClick={handleTabClick}
      />
    </div>
  )
}

export default withPage(withRoute(DriverAppShipmentsPage));
