import axios from 'axios';
import Cookies from 'js-cookie';
import { AUTH_KEY } from '../constants';
import configEnv from '../configs/env';

export default async function(endpoint: string, opts: any = {}): Promise<{response?: any, error?: any}> {
  const { headers, ...options } = opts;
  const config = Object.assign(
    {
      url: endpoint,
      baseURL: configEnv.REACT_APP_ENVOI_API_URI,
      headers: headers || {
        "Content-Type": "application/json",
        "Authorization": Cookies.get(AUTH_KEY)
      },
      timeout: 2*60000
    },
    options
  );
  try {
    const response = await axios.request(config);
    return ({ response: response.data });
  }
  catch (e) {
    const error = e as any;
    if (error.response) {
      return {
        error: {
          status: error.response.data.status || error.request?.status,
          message: error.response.data.message,
          errors: error.response.data.error?.errors || []
        }
      };
    }
    if (error.request && error.request._response) {
      return { error: { message: error.request._response } };
    }
    return { error: { message: error.message } };
  }
}
