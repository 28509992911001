import React, { useEffect } from 'react';

type Props = {
  videoId: string
}

const WistiaVideo: React.FC<Props> = ({videoId}) => {
  useEffect(() => {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");
    script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
    script1.async = true;
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;
    document.body.appendChild(script1);
    document.body.appendChild(script2);
    // eslint-disable-next-line
  },[]);
  return (
    <div className={`wistia_embed wistia_async_${videoId} videoFoam=true`}/>
  )
}

export default WistiaVideo;