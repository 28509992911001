import React, { useContext } from 'react';
import { Select } from 'antd';
import { LocaleContext, LANGUAGES } from '../contexts/locale';
import './LanguageSelect.scss';

type Props = {
  defaultLanguage?: string
  style?: any
}

const { Option } = Select;

const LanguageSelectInput: React.FC<Props> = ({
  defaultLanguage,
  style
}) => {
  const localeContext = useContext(LocaleContext);
  const language = defaultLanguage || localeContext?.language || 'en';
  const handleLanguageChange = (context: any, value: string) => {
    context.changeLanguage(value);
  }

  return (
    <div className='language-select' style={style}>
      <Select
        defaultValue={LANGUAGES[language]?.name || LANGUAGES['en'].name}
        onChange={(value: string) => handleLanguageChange(localeContext, value)}
        style={{ width: 100 }}
      >
        {
          Object.values(LANGUAGES).map(
            (language: any, index: number) =>
              <Option key={index} value={language.code}>
                {language.name}
              </Option>
          )
        }
      </Select>
    </div>
  )
}

export default LanguageSelectInput;
