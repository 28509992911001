import React from "react";
import { connect } from "react-redux";
import { State, doWipeError } from "../index";

export type Props = {
  error: State
  doWipeError: () => void
}

export default <T extends Props>(Component: React.ComponentType<T>) => {
  class Container extends React.Component<Props> {
    render() {
      return <Component {...this.props as T} />;
    }
  }
  function mapStateToProps(state: { error: State }) {
    const { error } = state
    return {
      error
    }
  }
  return connect(mapStateToProps, { doWipeError })(Container);
}
