import React from "react";
import { connect } from "react-redux";
import { Account } from "../../account/types";
import { State } from "../../auth/types";

export type Props = {
  account?: Account
}

export default <T extends Props>(Component: React.ComponentType<T>): any => {
  class Container extends React.Component<Props> {
    render() {
      return <Component {...this.props as T} />;
    }
  }
  function mapStateToProps({ auth }: { auth: State }) {
    return {
      account: auth.account
    }
  }
  return connect(mapStateToProps)(Container);
}