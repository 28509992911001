import React from 'react';
import ShipmentView from './Shipment';
import './Shipments.scss';
import { useIntl } from "react-intl";

type Props = {
  shipmentIds: string[]
  disabled?: boolean
  onShipmentClick?(id: string): void
}

const DriverAppShipments: React.FC<Props> = ({
  shipmentIds,
  disabled,
  onShipmentClick
}) => {
  const intl = useIntl();
  const handleItemClick = (id: string) => () => {
    onShipmentClick && onShipmentClick(id);
  }
  return (
    <div className="DriverAppShipments">
      {disabled &&
        <div className="cover" />
      }
      {shipmentIds.length === 0 &&
        <div className="no-shipments">{intl.formatMessage({id: 'DELIVERY.NO_SHIPMENTS'})}</div>
      }
      {shipmentIds.map(id =>
        <ShipmentView key={id} id={id} onClick={handleItemClick(id)} />
      )}
    </div>
  )
}

export default DriverAppShipments;
