import React from 'react';
import { useIntl } from "react-intl";
import './DriverContract.scss';

const DriverContract: React.FC = () => {
  const intl = useIntl();
  return (
    <div className="DriverContract">
      {intl.formatMessage(
        { id: 'CONTRACT_CONTENT.CONTENT' },
        {
          li: (chunks: any) => <li>{chunks}</li>,
          ol: (chunks: any) => <ol>{chunks}</ol >,
          p: (chunks: any) => <p>{chunks}</p>,
          strong: (chunks: any) => <strong>{chunks}</strong>,
          companyLink: <a href="https://www.yourenvoi.com" target="_blank" rel="noopener noreferrer">https://www.yourenvoi.com</a>,
          policyLink: <a href="https://www.yourenvoi.com/privacy" target="_blank" rel="noopener noreferrer">https://www.yourenvoi.com/privacy.</a>,
          linebreak: <br/>
        }
      )}
    </div>
  );
}
export default DriverContract;
