import React from 'react';
import useItems, { ItemsProps, ITEM_TYPE } from './hooks/useItems';
import BaseForm, { BaseFormProps } from './Base';
import { useIntl } from "react-intl";

type Props = BaseFormProps

const EmailAddressForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  const items: ItemsProps[] = [
    {
      formItemProps: { name: 'email', label: intl.formatMessage({id: 'GLOBAL.EMAIL'}) },
      required: true,
      type: ITEM_TYPE.EMAIL
    }
  ];
  return (
    <BaseForm
      {...props}
      submitText={intl.formatMessage({id: 'ACTION.SUBMIT'})}
    >
      {useItems(items)}
    </BaseForm>
  )
}

export default EmailAddressForm;
