import { Alert, Button, Card, Checkbox, Col, Result, Row } from 'antd';
import React, { useState } from 'react';
import { QuestionCircleFilled, ArrowLeftOutlined } from '@ant-design/icons';
import DriverApplicationForm, { Step, STEP_KEY } from '../forms/DriverApplication';
import { WistiaVideo } from '../videos';
import './DriverApplication.scss';
import { Link } from 'react-router-dom';
import withAccount, { AccountProps } from '../../modules/auth/hocs/withAccount';
import withApplication, { ApplicationProps } from '../../modules/driver-application/hocs/withApplication';
import withPage, { PageProps } from './withPage';
import { WEEKDAYS } from '../../constants';
import { DRIVER_STATUS } from '../../modules/driver/types';
import { useIntl } from "react-intl";
import { LanguageSelectInput } from '../inputs';
type Props = ApplicationProps & PageProps & AccountProps;

const DriverApplicationPage: React.FC<Props> = ({
  driver,
  account,
  request,
  query,
  pushTo,
  history,
  doUpdate
}) => {
  const intl = useIntl();

  const STEPS: Step[] = [
    {key: STEP_KEY.DRIVERS_LICENSE, name: intl.formatMessage({id: 'DRIVER_APPLICATION.DRIVER_LICENSE'}), desc: intl.formatMessage({id: 'DRIVER_APPLICATION.DRIVER_LICENSE_HINT'})},
    {key: STEP_KEY.VEHICLE_OWNERSHIP, name: intl.formatMessage({id: 'DRIVER_APPLICATION.VEHICLE_OWNERSHIP'}), desc: intl.formatMessage({id: 'DRIVER_APPLICATION.VEHICLE_OWNERSHIP_HINT'})},
    {key: STEP_KEY.VEHICLE_INSURANCE, name: intl.formatMessage({id: 'DRIVER_APPLICATION.VEHICLE_INSURANCE'}), desc: intl.formatMessage({id: 'DRIVER_APPLICATION.VEHICLE_INSURANCE_HINT'})},
    {key: STEP_KEY.VEHICLE_DETAILS, name: intl.formatMessage({id: 'DRIVER_APPLICATION.VEHICLE_DETAILS'}), desc: intl.formatMessage({id: 'DRIVER_APPLICATION.VEHICLE_DETAILS_HINT'})},
    {key: STEP_KEY.WEEKLY_AVAILABILITY, name: intl.formatMessage({id: 'DRIVER_APPLICATION.WEEKLY_AVAILABILITY'}), desc: intl.formatMessage({id: 'DRIVER_APPLICATION.WEEKLY_AVAILABILITY_HINT'})},
    {key: STEP_KEY.HOME_ADDRESS, name: intl.formatMessage({id: 'DRIVER_APPLICATION.HOME_ADDRESS'}), desc: intl.formatMessage({id: 'DRIVER_APPLICATION.HOME_ADDRESS_HINT'})},
    {key: STEP_KEY.PAYMENT, name: intl.formatMessage({id: 'DRIVER_APPLICATION.PAYMENT_INFOMATION'}), desc: intl.formatMessage({id: 'DRIVER_APPLICATION.PAYMENT_INFOMATION_HINT'})},
    {key: STEP_KEY.CONTRACT, name: intl.formatMessage({id: 'DRIVER_APPLICATION.CONTRACT_AGREEMENT'}), desc: '.'}
  ]

  const [confirmed, setConfirmed] = useState<boolean>(false);
  const stepIndex = query.step ? Number(query.step) : -1;
  const step = stepIndex > -1 ? STEPS[stepIndex] : undefined;
  const nextStep = stepIndex > -1 && stepIndex < STEPS.length ? STEPS[stepIndex+1] : undefined;
  const requiredSteps: Step[] = [];
  const completedSteps: Step[] = [];
  STEPS.forEach(step => {
    let incompleted = true;
    switch (step.key) {
      case STEP_KEY.DRIVERS_LICENSE:
        incompleted = !driver?.license;
        break;
      case STEP_KEY.VEHICLE_OWNERSHIP:
        incompleted = !driver?.vehicleOwnership;
        break;
      case STEP_KEY.VEHICLE_INSURANCE:
        incompleted = !driver?.vehicleInsurance;
        break;
      case STEP_KEY.VEHICLE_DETAILS:
        incompleted = (
          !driver?.licensePlateNumber ||
          !driver?.vehicleMake ||
          !driver?.vehicleModel ||
          !driver?.vehicleType ||
          !driver?.vehicleColour
        );
        break;
      case STEP_KEY.WEEKLY_AVAILABILITY:
        incompleted = WEEKDAYS.filter(w => driver && (driver as any)[`w${w}`]).length === 0;
        break;
      case STEP_KEY.HOME_ADDRESS:
        incompleted = (
          !driver?.homeAddress ||
          !driver?.homeLatitude ||
          !driver?.homeLongitude
        )
        break;
      case STEP_KEY.PAYMENT:
        incompleted = (
          !driver?.bankInstitution ||
          !driver?.branchNumber ||
          !driver?.institutionNumber ||
          !driver?.accountNumber
        )
        break;
      case STEP_KEY.CONTRACT:
        incompleted = !driver?.contractAccepted;
        break;
    }
    if (incompleted) requiredSteps.push(step)
    else completedSteps.push(step);
  });
  const isCompleted = completedSteps.length === STEPS.length;
  const isSubmitted = driver?.status === DRIVER_STATUS.SUBMITTED_DOCUMENT;
  const isRejected = driver?.status === DRIVER_STATUS.REJECTED;
  const isPreparing = driver?.status === DRIVER_STATUS.SIGNED_UP || driver?.status === DRIVER_STATUS.PREPARING_DOCUMENT;
  const handleApplicationSubmit = () => {
    doUpdate({ ...driver, id: 'me', status: DRIVER_STATUS.SUBMITTED_DOCUMENT});
  }
  const handleDriverApplicationFormSubmit = (values: any) => {
    doUpdate({ id: 'me', stepIndex, ...values, status: DRIVER_STATUS.PREPARING_DOCUMENT });
  }
  const handleStepChange = (i: number) => {
    const currIndex =  (i > -1 && i < STEPS.length) ? i : undefined;
    pushTo({query: {step: currIndex }});
  }
  return (
    <div className="DriverApplicationPage" style={{minHeight: window.innerHeight}}>
      <LanguageSelectInput/>
      <div className="header">
        <Row>
          <Col lg={{span: 12, offset: 6}} sm={{span: 18, offset: 3}} xs={24}>
            <div className="logo">
              <img src="/logo.svg" alt="lg" />
            </div>
            <div className="question">
              {intl.formatMessage({id: 'GLOBAL.FAQ'})} <QuestionCircleFilled />
            </div>
          </Col>
        </Row>
      </div>
      <div className="content">
        <Row>
          <Col lg={{span: 12, offset: 6}} sm={{span: 18, offset: 3}} xs={24}>
            {step && driver && isPreparing &&
              <Card>
                <div className="card-body">
                  <div className="group">
                    <div className="name">
                      <Button
                        size="small"
                        type="link"
                        icon={<ArrowLeftOutlined />}
                        onClick={() => history.goBack()}
                      />
                      {step.name}
                    </div>
                    <div className="desc">{step.desc}</div>
                  </div>
                  <DriverApplicationForm
                    driver={driver}
                    step={step}
                    disabled={isSubmitted}
                    nextStep={nextStep}
                    loading={request.isFetching}
                    onSubmit={handleDriverApplicationFormSubmit}
                  />
                </div>
              </Card>
            }
            {!step &&
              <Card
                title={`${intl.formatMessage({id: isSubmitted ? 'GLOBAL.THANK_YOU' : 'GLOBAL.WELCOME'})}, ${account?.name}`}
                extra={<Link to="/logout">{intl.formatMessage({id: 'ACTION.SIGN_OUT'})}</Link>}
              >
                {isSubmitted &&
                  <Result
                    status="success"
                    title={intl.formatMessage({id: 'DRIVER_APPLICATION.APPLICATION_SUBMITTED'})}
                    subTitle={intl.formatMessage({id: 'DRIVER_APPLICATION.APPLICATION_SUBMITTED_MESSAGE'})}
                  />
                }
                {isRejected &&
                  <Result
                    status="error"
                    title={intl.formatMessage({id: 'DRIVER_APPLICATION.APPLICATION_REJECTED'})}
                    subTitle={intl.formatMessage({id: 'DRIVER_APPLICATION.APPLICATION_REJECTED_MESSAGE'})}
                  />
                }
                {isPreparing &&
                  <>
                    <Alert message={intl.formatMessage({id: 'DRIVER_APPLICATION.AUTO_SAVE_ALERT'})} type="info" showIcon />
                    <div className="card-body">
                      <div className="group">
                        <div className="name">{intl.formatMessage({id: 'GLOBAL.DRIVING_WITH_ENVOI'})}</div>
                        <div className="desc">{intl.formatMessage({id: 'DRIVER_APPLICATION.WATCH_VIDEO_MESSAGE'})}</div>
                      </div>
                      <div className="video">
                        <WistiaVideo videoId="ozbhhb0hzu" />
                      </div>
                      {requiredSteps.length > 0 &&
                        <div className="group">
                          <div className="name">{intl.formatMessage({id: 'DRIVER_APPLICATION.REQUIRED_STEPS'})}</div>
                          <div className="desc">{intl.formatMessage({id: 'DRIVER_APPLICATION.REQUIRED_STEPS_MESSAGE'})}</div>
                        </div>
                      }
                      <div className="steps">
                        {requiredSteps.map(step =>
                          <div
                            key={step.key}
                            className="group small"
                            onClick={() => handleStepChange(STEPS.indexOf(step))}
                          >
                            <div className="status-icon" />
                            <div className="name">{step.name}</div>
                            <div className="desc">{intl.formatMessage({id: 'ACTION.GET_STARTED'})}</div>
                          </div>
                        )}
                      </div>
                      <div className="group">
                        <div className="name">{intl.formatMessage({id: 'DRIVER_APPLICATION.COMPLETED_STEPS'})}</div>
                        {isCompleted &&
                          <div className="desc">{intl.formatMessage({id: 'DRIVER_APPLICATION.COMPLETED_STEPS_MESSAGE'})}</div>
                        }
                      </div>
                      <div className="steps">
                        {completedSteps.map(step =>
                          <div
                            key={step.key}
                            className="group small"
                            onClick={() => handleStepChange(STEPS.indexOf(step))}
                          >
                            <div className="status-icon done" />
                            <div className="name">{step.name}</div>
                            <div className="desc">{intl.formatMessage({id: 'ACTION.GET_STARTED'})}</div>
                          </div>
                        )}
                        <div className="group small">
                          <div className="status-icon done" />
                          <div className="name">{intl.formatMessage({id: 'DRIVER_APPLICATION.ACCOUNT_CREATION'})}</div>
                          <div className="desc">{intl.formatMessage({id: 'GLOBAL.WELCOME_BOARD'})}</div>
                        </div>
                      </div>
                      {!isSubmitted &&
                        <>
                          <div className="confirm-checkbox">
                            <Checkbox
                              disabled={!isCompleted}
                              checked={confirmed}
                              onChange={e => setConfirmed(e.target.checked)}
                            >
                              {intl.formatMessage({id: 'DRIVER_APPLICATION.SUBMIT_CONFIRM_MESSAGE'})}
                            </Checkbox>
                          </div>
                          <Button
                            disabled={!isCompleted || !confirmed}
                            loading={request.isFetching}
                            onClick={handleApplicationSubmit}
                            block
                            type="primary"
                            shape="round"
                            size="large"
                          >
                            {intl.formatMessage({id: 'ACTION.SUBMIT_APPLICATION'})}
                          </Button>
                        </>
                      }
                    </div>
                  </>
                }
              </Card>
            }
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default React.memo(withPage(props => {
  const Page = withAccount(withApplication(DriverApplicationPage))
  return <Page {...props} id="me" />;
}));
