import React, { useEffect } from 'react';
import { notification } from 'antd';
import withError, { Props } from '../modules/error/hocs/withError';

const AppError: React.FC<Props> = ({
  error: { message },
  doWipeError
}) => {
  useEffect(() => {
    if (message) {
      notification.error({
        message: message,
        placement: 'bottomRight',
        onClose: () => doWipeError()
      });
    }
    // eslint-disable-next-line
  }, [message]);
  return null;
}

export default withError(AppError);