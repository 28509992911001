import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import configStore from './configs/store';
import { RootModule } from 'react-easy-module';
import { Provider } from 'react-redux';
import './custom.less';
import './custom.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import { LocaleContextProvider } from './components/contexts/locale';

const { store, history } = configStore();

ReactDOM.render(
  // <React.StrictMode>
  <LocaleContextProvider>
    <Provider store={store}>
      <RootModule history={history}>
        <App />
      </RootModule>
    </Provider>
  </LocaleContextProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
