
import { IModuleState } from "react-easy-module/es/module";
import { Driver } from "../driver/types";
import { Order } from '../order/types';

export enum SHIPMENT_STATUS {
  DELIVERY_SCHEDULED = 'DELIVERY_SCHEDULED',
  AT_WAREHOUSE = 'AT_WAREHOUSE',
  ATTEMPTING_DELIVERY = 'ATTEMPTING_DELIVERY',
  COMPLETED = 'COMPLETED',
  SKIPPED = 'SKIPPED',
  CANCELED = 'CANCELED',
  NOT_RECEIVED = 'NOT_RECEIVED'
}

export enum SHIPMENT_TYPE {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY'
}

export enum SHIPMENT_STOP_STATUS {
  REQUIRED_SKILL = 'REQUIRED_SKILL', // cannot serve required skill
  TIME_WINDOW_CONSTRAINT = 'TIME_WINDOW_CONSTRAINT', // cannot be visited within time window
  CAPACITY_CONSTRAINT = 'CAPACITY_CONSTRAINT', // does not fit into any vehicle due to capacity
  DISTANCE_CONSTAINT = 'DISTANCE_CONSTAINT', // cannot be assigned due to max distance constraint of vehicle
  NO_VEHICLE = 'NO_VEHICLE', // No vehicle available during the specified time windows.
  UNSCHEDULED = 'UNSCHEDULED',
  BAD_ADDRESS = 'BAD_ADDRESS' // Missing Lat/Long - Check Address, The lat/lng is too far from the rest of the visits; likely a geocoding error?
}

export type Shipment = {
  id: string;
  date: string;
  time: string;
  timeToPrepare: number;
  routeId?: number;
  stopNumber?: number;
  stopStartTime?: string;
  stopEndTime?: string;
  stopStatus?: SHIPMENT_STOP_STATUS;
  longitude: number;
  latitude: number;
  driverId?: number;
  driverName?: string;
  driverNote?: string;
  signature?: string;
  photos?: string[];
  pickupScheduleId?: string;
  pickupScheduleName?: string;
  status: SHIPMENT_STATUS;
  statusId?: number;
  statusNote?: string;
  statusUpdatedAt?: string;
  type: string;
  order: Order;
  driver?: Driver;
  sortedAt: Date;
  callHistories?: any[];
  isCurrent?: boolean;
  isLast?: boolean;
  mpsStops?: Shipment[];
  clusterId?: string;
}

export type Metadata = {
  current: number;
  pageSize: number;
  total: number;
  ids: string[]
}

export type ShipmentStats = {
  date: string
  total: number
  pending: number
  completed: number
  skipped: number
  canceled: number
  notReceived: number
  resubmitted: number
  package?: {
    large: number
    normal: number
    additional: number
  }
  retailer?: string
  pickupPoint?: string
  pickupSchedule?: string
  serviceArea?: string
}

export type ShipmentStatsRO = {
  pageSize?: number
  current?: number
  summary?: ShipmentSummary
  stats?: ShipmentStats[]
  total?: number
}

export type ShipmentSummary = {
  rowsCount: number
  total: number
  pending: number
  completed: number
  skipped: number
  canceled: number
  notReceived: number
  resubmitted: number
}

export type State = IModuleState & {
  items: { [key: string]: Shipment }
  stats?: ShipmentStatsRO,
  metadata: Metadata
}
