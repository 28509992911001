import React, { useState, ReactNode, useEffect, forwardRef } from 'react'
import { Form, Space, Button, Alert } from 'antd';
import { FormProps, FormInstance } from 'antd/lib/form';
import { useIntl } from "react-intl";

type ErrorProps = {
  type: string
  message: string
}

export type Ref = FormInstance;

export type BaseFormProps = {
  formProps?: FormProps
  loading?: boolean
  errors?: ErrorProps[]
  onSubmit?(_:any): void
  onCancel?(): void
  submitText?: ReactNode
  submitBtnVisible?: boolean
  submitAlwaysEnabled? :boolean
  cancelText?: ReactNode
  cancelBtnVisible?: boolean
  extraAction?: ReactNode
  children?: any
}

const BaseForm = forwardRef<Ref, BaseFormProps>(({
  formProps,
  loading,
  errors,
  onSubmit,
  onCancel,
  submitText,
  submitBtnVisible = true,
  submitAlwaysEnabled = false,
  cancelText,
  cancelBtnVisible = false,
  extraAction,
  children
}, ref) => {
  const intl = useIntl();
  const form = formProps?.form || Form.useForm()[0];
  const [changed, setChanged] = useState<boolean>(false);
  useEffect(() => {
    if (errors && Array.isArray(errors)) {
      const fields = [];
      for (let error of errors) {
        if (error.type && error.message) {
          fields.push({
            name: error.type.split('.'),
            errors: [error.message]
          })
        }
      }
      form?.setFields(fields);
    }
    // eslint-disable-next-line
  }, [errors])
  const onFinish = (values: any) => {
    setChanged(false);
    onSubmit && onSubmit(values);
  }
  const onCancelClick = () => {
    form?.resetFields();
    setChanged(false);
    onCancel && onCancel();
  }
  function handleFieldsChange() {
    setChanged(true);
  }
  return (
    <>
      {errors && errors.length > 0 &&
        <>
          <Alert
            message={intl.formatMessage({id: 'ERROR.VALIDATION_ERROR'})}
            closable
            description={
              <ul>
                {errors.map((error, i) =>
                  <li key={i}><strong>{error.type}: </strong>{error.message}</li>
                )}
              </ul>
            }
            type="error"
          />
          <br />
        </>
      }
      <Form
        ref={ref}
        form={form}
        autoComplete="off"
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={onFinish}
        onFieldsChange={handleFieldsChange}
        {...formProps}
      >
        {children}
        {submitBtnVisible &&
          <Space>
            <Button
              loading={loading}
              className="form-submit-button"
              htmlType="submit"
              disabled={!changed && !submitAlwaysEnabled}
            >
              {submitText}
            </Button>
            {(changed || cancelBtnVisible) &&
              <Button className="form-cancel-button" onClick={onCancelClick}>{cancelText || intl.formatMessage({id: 'ACTION.CANCEL'})}</Button>
            }
            {extraAction}
          </Space>
        }
      </Form>
    </>
  )
})

export default BaseForm;
