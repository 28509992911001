import request from '../../utils/request';
export { update as updateShipmentStatus } from '../shipment/api';

export function detail(params: any) {
  const { id, ...data } = params;
  return request(`/shipment-routes/${id}`, {
    method: 'GET',
    params: data
  });
}

export function startRoute(params: any) {
  const { id } = params;
  return request(`/shipment-routes/${id}/start`, {
    method: 'PUT'
  });
}