import React from "react";
import { connect } from "react-redux";
import { doDetail, doUpdate } from "../index";
import { State, Driver } from "../types";
import { applicationSelector } from "../selectors";

export type ApplicationProps = {
  id: string
  request: any
  driver?: Driver
  doDetail(_: any): void
  doUpdate(_: any): void
}

type IOwnProps = {
  id: string
}

export default <T extends ApplicationProps>(Component: React.ComponentType<T>): any => {
  class Container extends React.Component<ApplicationProps> {
    componentDidMount() {
      const { request, id, doDetail } = this.props;
      if (id && !request?.made) {
        doDetail({id});
      }
    }
    render() {
      return <Component {...this.props as T} />;
    }
  }
  function mapStateToProps({ driverApplication }: { driverApplication: State }, props: IOwnProps) {
    const { id } = props;
    return {
      id,
      request: driverApplication.request[id] || {},
      driver: applicationSelector(driverApplication, id)
    }
  }
  return connect(
    mapStateToProps,
    {
      doDetail,
      doUpdate
    }
  )(Container);
}
